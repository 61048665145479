import { StatusIndicator } from "@amzn/awsui-components-react";
import {
  formatCurrency,
  formatDateTime,
  getIndividualRefundStatus,
} from "../../../helpers/dbdUtils";
import { ExpandableTableSection } from "./ExpandableTableSection";
import { Refund } from "../../../types/documents";

interface RefundsTableProps {
  title: string;
  refunds: Record<string, Refund>;
}

interface RefundsTableProps {
  title: string;
  refunds: Record<string, Refund>;
}

export function RefundsTable({ title, refunds }: RefundsTableProps) {
  const items = Object.entries(refunds).map(([refundId, refund]) => {
    const status = getIndividualRefundStatus(refund);

    return {
      refundId,
      date: refund.at ? formatDateTime(refund.at) : undefined,
      amount: formatCurrency(
        refund.amount.customerTotalAmountWithoutTax.value,
        refund.amount.customerTotalAmountWithoutTax.currencyCode
      ),
      taxAmount: formatCurrency(
        refund.amount.customerTaxAmount.value,
        refund.amount.customerTaxAmount.currencyCode
      ),
      status: status && (
        <StatusIndicator type={status.type}>{status.text}</StatusIndicator>
      ),
      reason: refund.reason,
    };
  });

  const hasDateColumn = items.some((item) => item.date !== undefined);
  const hasStatusColumn = items.some((item) => item.status !== null);

  const columnDefinitions = [
    { header: "Refund ID", cell: (item) => item.refundId },
    ...(hasDateColumn
      ? [{ header: "Date", cell: (item) => item.date || "" }]
      : []),
    { header: "Amount", cell: (item) => item.amount },
    { header: "Tax", cell: (item) => item.taxAmount },
    ...(hasStatusColumn
      ? [{ header: "Status", cell: (item) => item.status }]
      : []),
    { header: "Reason", cell: (item) => item.reason },
  ];

  return (
    <ExpandableTableSection
      title={title}
      items={items}
      columnDefinitions={columnDefinitions}
    />
  );
}
