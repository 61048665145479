import React, { useContext, useState } from "react";
import { Container, Grid, Header } from "@amzn/awsui-components-react";
import EventsVersionSelector from "./EventsVersionSelector";
import { OrderIdContext, StageContext } from "../../contexes/OrderIdContext";
import { useMarketplaceId } from "../../contexes/MarketplaceIdContext";
import useFetchDetails from "../../hooks/useFetchDetails";
import {
  getCbeLogGroups,
  getEventDateRange,
  getEventLogGroups,
} from "../../helpers/logUtils";
import CbeVersionSelector from "../cbe/CbeVersionSelector";
import LogButtons from "../common/LogButtons";
import ErrorAlert from "../common/ErrorAlert";

export default function Results() {
  const orderId = useContext(OrderIdContext);
  const stage = useContext(StageContext);
  const { marketplaceId } = useMarketplaceId();

  const { data: detailsData } = useFetchDetails(stage, orderId);

  const eventData = detailsData?.events;
  const cbeData = detailsData?.cbes;

  const isEventNotEmpty = eventData !== undefined && eventData.length !== 0;
  const isCbeNotEmpty = cbeData !== undefined && cbeData.length !== 0;

  const [selectedEventIndex, setSelectedEventIndex] = useState(() => {
    return isEventNotEmpty ? eventData.length - 1 : 0;
  });
  const [selectedCbeIndex, setSelectedCbeIndex] = useState(() => {
    return isCbeNotEmpty ? cbeData.length - 1 : 0;
  });

  const eventLogGroups = isEventNotEmpty
    ? getEventLogGroups(stage, eventData)
    : [];
  const cbeLogGroups = getCbeLogGroups(marketplaceId, stage);

  const handleEventVersionChange = (index: number) => {
    setSelectedEventIndex(index);
  };

  const handleCbeVersionChange = (index: number) => {
    setSelectedCbeIndex(index);
  };

  return (
    <Grid
      gridDefinition={[
        { colspan: { default: 12, s: 6 } },
        { colspan: { default: 12, s: 6 } },
      ]}
    >
      <Container
        header={
          <Header
            variant="h2"
            actions={
              isEventNotEmpty &&
              eventData && (
                <LogButtons
                  identifier={orderId}
                  stage={stage}
                  logGroups={eventLogGroups}
                  dateRange={getEventDateRange(
                    eventData[selectedEventIndex],
                    false
                  )}
                />
              )
            }
          >
            Events
          </Header>
        }
      >
        {isEventNotEmpty ? (
          <EventsVersionSelector
            eventData={eventData}
            onVersionChange={handleEventVersionChange}
          />
        ) : (
          <ErrorAlert header="Could not find any events" content="" />
        )}
      </Container>
      <Container
        header={
          <Header
            variant="h2"
            actions={
              isCbeNotEmpty &&
              cbeData && (
                <LogButtons
                  identifier={orderId}
                  stage={stage}
                  logGroups={cbeLogGroups}
                  dateRange={getEventDateRange(cbeData[selectedCbeIndex], true)}
                />
              )
            }
          >
            CBEs
          </Header>
        }
      >
        {isCbeNotEmpty ? (
          <CbeVersionSelector
            cbeData={cbeData}
            onVersionChange={handleCbeVersionChange}
          />
        ) : (
          <ErrorAlert header="Could not find any CBEs" content="" />
        )}
      </Container>
    </Grid>
  );
}
