import React, { createContext, useContext, useState } from "react";
import { HealthState } from "../types";

export const initialHealthState: HealthState = {
  overallHealth: "unknown",
  eventsReceived: false,
  cbeStatus: {
    produced: false,
    published: false,
  },
};

export const HealthStateContext = createContext<HealthState | undefined>(
  undefined
);
HealthStateContext.displayName = "Health Context";

interface HealthStateUpdateContextValue {
  setState: React.Dispatch<React.SetStateAction<HealthState>>;
  resetHealthState: () => void;
}

const HealthStateUpdateContext = createContext<
  HealthStateUpdateContextValue | undefined
>(undefined);

export const HealthStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<HealthState>(initialHealthState);

  const resetHealthState = () => {
    setState(initialHealthState);
  };

  return (
    <HealthStateContext.Provider value={state}>
      <HealthStateUpdateContext.Provider value={{ setState, resetHealthState }}>
        {children}
      </HealthStateUpdateContext.Provider>
    </HealthStateContext.Provider>
  );
};

export const useHealthState = () => {
  const context = useContext(HealthStateContext);
  if (!context) {
    throw new Error("useHealthState must be used within a HealthStateProvider");
  }
  return context;
};

export const useHealthStateUpdate = () => {
  const context = useContext(HealthStateUpdateContext);
  if (!context) {
    throw new Error(
      "useHealthStateUpdate must be used within a HealthStateProvider"
    );
  }
  return context;
};
