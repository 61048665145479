import { useContext } from "react";
import {
  Box,
  ColumnLayout,
  Container,
  Select,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Input from "@amzn/awsui-components-react/polaris/input";
import { OrderIdContext, StageContext } from "../contexes/OrderIdContext";
import { BETA_STAGE, GAMMA_STAGE, PROD_STAGE } from "../constants";
import { useSearch } from "../hooks/useSearch";

interface Props {
  children: React.ReactNode;
  setStage: React.Dispatch<React.SetStateAction<string>>;
}

export default function SearchBar({ children, setStage }: Props) {
  const stage = useContext(StageContext) || "prod";

  const {
    orderId,
    orderIdQuery,
    isStageDropdownDisabled,
    selectedOption,
    setOrderIdQuery,
    handleSearch,
    handleStageSelect,
    handleKeyDown,
  } = useSearch(stage, setStage);

  return (
    <OrderIdContext.Provider value={orderId}>
      <SpaceBetween size="xl">
        <Container>
          <ColumnLayout columns={1}>
            <Box padding={{ top: "s", bottom: "s" }}>
              <div className="custom-search-bar">
                <div className="custom-search-bar-input">
                  <Input
                    onChange={({ detail }) =>
                      setOrderIdQuery(detail.value.trim())
                    }
                    value={orderIdQuery}
                    onKeyDown={({ detail }) => handleKeyDown(detail)}
                    type="search"
                    placeholder="Enter Transaction Id (TVOD: Order ID, Subs: AUID)"
                    autoFocus
                  />
                </div>
                <Select
                  className="custom-select"
                  selectedOption={selectedOption}
                  placeholder="Choose an environment"
                  onChange={({ detail }) =>
                    handleStageSelect(detail.selectedOption)
                  }
                  options={[
                    { label: PROD_STAGE, value: PROD_STAGE },
                    { label: GAMMA_STAGE, value: GAMMA_STAGE },
                    { label: BETA_STAGE, value: BETA_STAGE },
                  ]}
                  disabled={isStageDropdownDisabled}
                  selectedAriaLabel="Selected"
                />
                <div className="custom-search-bar-items">
                  <Button
                    onClick={handleSearch}
                    variant="primary"
                    className="inp"
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Box>
          </ColumnLayout>
        </Container>
        {children}
      </SpaceBetween>
    </OrderIdContext.Provider>
  );
}
