import { formatCurrencyValue } from "./cbeSummaryUtils";
import {
  BalanceCheckResult,
  BalanceStatusResult,
  CanonicalBusinessEvent,
  CBERecord,
  CurrencyTotals,
  GroupedRecordsInterface,
} from "../types/cbes";

export const BALANCE_CHECK_MESSAGES = {
  MULTIPLE_CURRENCIES:
    "Balance check was skipped because this CBE contains multiple currencies. Balance checking is only supported for single-currency CBEs due to intercompany records being derived in Flare.",
  RACE_SINGLE_RECORD:
    "Balance check was skipped because this is a RACE CBE for a cancellation, and is only expected to have one file.",
};

export function shouldSkipBalanceCheck(
  records: CBERecord[],
  cbe: CanonicalBusinessEvent
): BalanceCheckResult {
  // MULTIPLE_CURRENCIES check
  const uniqueCurrencies = new Set(
    records.map(
      (record) =>
        record.content?.functionalAmount?.currencyCode ||
        record.functionalAmount?.currencyCode
    )
  );
  if (uniqueCurrencies.size > 1) {
    return {
      skip: true,
      message: BALANCE_CHECK_MESSAGES.MULTIPLE_CURRENCIES,
    };
  }

  // RACE_SINGLE_RECORD check
  const isRaceCbe = cbe.destination?.includes("RACE");
  if (isRaceCbe) {
    const recordsByGroup = records.reduce((acc, record) => {
      const group =
        record.content?.financialComponentGroup ||
        record.flareConfigFinancialComponentGroup ||
        "Unknown";

      if (!acc[group]) {
        acc[group] = 0;
      }
      acc[group]++;
      return acc;
    }, {} as { [group: string]: number });

    const hasOnlyOneRecordPerGroup = Object.values(recordsByGroup).every(
      (count) => count === 1
    );

    if (hasOnlyOneRecordPerGroup) {
      return {
        skip: true,
        message: BALANCE_CHECK_MESSAGES.RACE_SINGLE_RECORD,
      };
    }
  }

  return { skip: false };
}

export function calculateDebitCreditTotals(
  debits: CBERecord[],
  credits: CBERecord[]
): CurrencyTotals {
  const totals: CurrencyTotals = {};

  // Process debits
  debits.forEach((record) => {
    const amount = Math.abs(
      parseFloat(
        record.content?.functionalAmount?.value ||
          record.functionalAmount?.value ||
          "0"
      )
    );
    const currencyCode =
      record.content?.functionalAmount?.currencyCode ||
      record.functionalAmount?.currencyCode ||
      "UNKNOWN";

    if (!totals[currencyCode]) {
      totals[currencyCode] = { debits: 0, credits: 0 };
    }
    totals[currencyCode].debits += amount;
  });

  // Process credits
  credits.forEach((record) => {
    const amount = Math.abs(
      parseFloat(
        record.content?.functionalAmount?.value ||
          record.functionalAmount?.value ||
          "0"
      )
    );
    const currencyCode =
      record.content?.functionalAmount?.currencyCode ||
      record.functionalAmount?.currencyCode ||
      "UNKNOWN";

    if (!totals[currencyCode]) {
      totals[currencyCode] = { debits: 0, credits: 0 };
    }
    totals[currencyCode].credits += amount;
  });

  return totals;
}

export function checkDebitCreditsBalanced(totals: CurrencyTotals): {
  isBalanced: boolean;
  differences: { currencyCode: string; difference: number }[];
} {
  const differences: { currencyCode: string; difference: number }[] = [];
  let isBalanced = true;

  Object.entries(totals).forEach(([currencyCode, { debits, credits }]) => {
    const difference = Math.abs(debits - credits);
    if (difference > 0.01) {
      isBalanced = false;
      differences.push({
        currencyCode,
        difference,
      });
    }
  });

  return { isBalanced, differences };
}

export function groupRecordsByEntryType(
  records: CBERecord[]
): GroupedRecordsInterface {
  return records.reduce<GroupedRecordsInterface>((acc, record) => {
    const group =
      record.flareConfigFinancialComponentGroup ||
      record.content?.financialComponentGroup ||
      "Unknown";
    if (!acc[group]) {
      acc[group] = { debits: [], credits: [] };
    }
    const entryType = record.content?.entryType || record.entryType;
    if (entryType === "DEBIT") {
      acc[group].debits.push(record);
    } else if (entryType === "CREDIT") {
      acc[group].credits.push(record);
    }
    return acc;
  }, {});
}

export function getBalanceStatus(
  groupedRecords: GroupedRecordsInterface,
  skipResult: BalanceCheckResult
): BalanceStatusResult {
  if (skipResult.skip) {
    return {
      status: "Skipped",
      messages: skipResult.message ? [skipResult.message] : [],
    };
  }

  const tableData = Object.entries(groupedRecords)
    .map(([group, { debits, credits }]) => {
      const totals = calculateDebitCreditTotals(debits, credits);
      const { isBalanced, differences } = checkDebitCreditsBalanced(totals);

      return Object.entries(totals).map(
        ([currencyCode, { debits: debitTotal, credits: creditTotal }]) => ({
          group,
          debits: formatCurrencyValue(debitTotal, currencyCode),
          credits: formatCurrencyValue(creditTotal, currencyCode),
          difference: !isBalanced
            ? formatCurrencyValue(
                Math.abs(debitTotal - creditTotal),
                currencyCode
              )
            : undefined,
        })
      );
    })
    .flat();

  const hasErrors = tableData.some((item) => item.difference);

  return {
    status: hasErrors ? "Unbalanced" : "Balanced",
    messages: [],
  };
}
