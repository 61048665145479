import React from "react";
import { SpaceBetween } from "@amzn/awsui-components-react";
import { determineCbeGroupHealth } from "../../helpers/healthUtils";
import { HealthStatus } from "../../types/health";
import { CanonicalBusinessEvent } from "../../types";
import HealthStatusIndicator from "../common/HealthStatusIndicator";

function CbeHealthStatusDisplay({
  value,
  events,
  name,
}: {
  value: string;
  events: CanonicalBusinessEvent[];
  name: string;
}) {
  const health: HealthStatus = determineCbeGroupHealth(events);

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <span>
        {name} {value}
      </span>
      <HealthStatusIndicator healthStatus={health} minimal />
    </SpaceBetween>
  );
}

export default CbeHealthStatusDisplay;
