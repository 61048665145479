import {
  Container,
  Header,
  KeyValuePairs,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react";
import { ExpandableDbdSection } from "./ExpandableDbdSection";
import { formatDateTime } from "../../../helpers/dbdUtils";
import { Metadata } from "../../../types/documents";

interface MetadataSectionProps {
  metadata: Metadata;
  expanded: boolean;
  onChange: (event: { detail: { expanded: boolean } }) => void;
}

export function MetadataSection({
  metadata,
  expanded,
  onChange,
}: MetadataSectionProps) {
  const sortedEvents = Object.entries(metadata.events)
    .map(([eventName, event]) => ({
      eventName,
      ...event,
      timestamp: new Date(event.at).getTime(),
    }))
    .sort((a, b) => b.timestamp - a.timestamp);

  const latestEvent = sortedEvents[0];

  return (
    <ExpandableDbdSection
      title="Metadata"
      expanded={expanded}
      onChange={onChange}
      description={
        <small>
          Latest Event: {latestEvent?.eventName} | Version:{" "}
          {latestEvent?.version} | {formatDateTime(latestEvent?.at)}
        </small>
      }
    >
      <SpaceBetween size="l">
        <Container>
          <Header variant="h3">Attributes</Header>
          <KeyValuePairs
            columns={2}
            items={Object.entries(metadata.attributes).map(([key, value]) => ({
              label: key,
              value,
            }))}
          />
        </Container>

        {metadata.internalAttributes && (
          <Container>
            <Header variant="h3">Internal Attributes</Header>
            <KeyValuePairs
              columns={2}
              items={Object.entries(metadata.internalAttributes).map(
                ([key, value]) => ({
                  label: key,
                  value: String(value),
                })
              )}
            />
          </Container>
        )}

        <Container>
          <Header variant="h3">Events Timeline</Header>
          <Table
            items={sortedEvents}
            columnDefinitions={[
              { header: "Event", cell: (item) => item.eventName },
              { header: "Version", cell: (item) => item.version },
              {
                header: "Timestamp",
                cell: (item) => formatDateTime(item.at),
              },
            ]}
            variant="embedded"
            stripedRows
          />
        </Container>
      </SpaceBetween>
    </ExpandableDbdSection>
  );
}
