import {
  ColumnLayout,
  Container,
  Header,
  Steps,
} from "@amzn/awsui-components-react";
import React from "react";
import { SummaryItem } from "./SummaryItem";
import { useSummaryData } from "../../hooks/useSummaryData";
import ExternalLinks from "../ExternalLinks";
import {
  DigitalBusinessDocument,
  DigitalCostDocument,
} from "../../types/documents";
import { AccountingEventsGroup } from "../../types/events";
import HealthStatusIndicator from "../common/HealthStatusIndicator";
import { DlqInfo } from "../../hooks/useFetchDlqInfo";
import DlqStatusIndicator from "../dlq/DlqStatusIndicator";
import { screamingSnakeToTitleCase } from "../../helpers/dbdUtils";

interface SummaryProps {
  eventData: (
    | AccountingEventsGroup
    | DigitalBusinessDocument
    | DigitalCostDocument
  )[];
  dlqInfo: DlqInfo;
}

export default function Summary({ eventData, dlqInfo }: SummaryProps) {
  const {
    stage,
    orderId,
    countryCode,
    healthStatus,
    steps,
    orderDate,
    glProductGroup,
    consumptionType,
    fulfillmentType,
    getGlMapping,
    lastItem,
    isHealthLoading,
  } = useSummaryData(eventData, dlqInfo);

  return (
    <Container
      header={
        <Header variant="h1" description="Summary">
          {orderId}
        </Header>
      }
    >
      <ColumnLayout columns={5} borders="vertical">
        <SummaryItem label="Stage" value={stage} />
        <SummaryItem
          label="GL"
          value={glProductGroup ? getGlMapping(glProductGroup) : "-"}
        />
        <SummaryItem label="Country" value={countryCode} />
        <SummaryItem
          label="Order Date"
          value={orderDate ? new Date(orderDate).toUTCString() : "-"}
        />
        {consumptionType && (
          <SummaryItem label="Consumption" value={consumptionType} />
        )}
        {fulfillmentType && (
          <SummaryItem
            label="Fulfillment"
            value={screamingSnakeToTitleCase(fulfillmentType)}
          />
        )}
        <SummaryItem
          label="Status"
          value={
            <HealthStatusIndicator
              healthStatus={healthStatus}
              popoverContent={<Steps steps={steps} />}
              isLoading={isHealthLoading}
            />
          }
        />
        <SummaryItem
          label="DLQ Status"
          value={
            <DlqStatusIndicator
              status={dlqInfo.status}
              dlqItems={dlqInfo.dlqItems}
            />
          }
        />
        <SummaryItem
          label="External Links"
          value={<ExternalLinks {...lastItem} />}
        />
      </ColumnLayout>
    </Container>
  );
}
