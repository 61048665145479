import { Tabs } from "@amzn/awsui-components-react";
import EventResults from "./EventResults";
import { AccountingEventsGroup, DigitalBusinessDocument } from "../../types";

interface Props {
  eventData: (AccountingEventsGroup | DigitalBusinessDocument)[];
  onVersionChange: (index: number) => void;
}

const createVersionNumberArray = (length: number, startFrom: number) => {
  return Array.from({ length }, (_, i) => length - i + startFrom - 1);
};

export default function EventsVersionSelector(props: Props) {
  const { eventData, onVersionChange } = props;

  const isAccountingEventsGroup = "accountingEvents" in eventData[0];

  const startFrom = isAccountingEventsGroup ? 1 : 0; // DBD starts at v0

  const versionNumbers = createVersionNumberArray(eventData.length, startFrom);

  return (
    <Tabs
      tabs={versionNumbers.map((versionNumber) => ({
        id: `${versionNumber}`,
        label: `Version ${versionNumber}`,
        content: (
          <EventResults eventData={eventData[versionNumber - startFrom]} />
        ),
      }))}
      onChange={({ detail }) => {
        const selectedVersion = parseInt(detail.activeTabId);
        onVersionChange(selectedVersion - startFrom);
      }}
    />
  );
}
