import { ExpandableSection, Header, Table } from "@amzn/awsui-components-react";
import { useState } from "react";

interface ExpandableTableSectionProps {
  title: string;
  items: any[];
  columnDefinitions: any[];
}

export function ExpandableTableSection({
  title,
  items,
  columnDefinitions,
}: ExpandableTableSectionProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <ExpandableSection
      defaultExpanded
      header={<Header variant="h3">{title}</Header>}
      expanded={isExpanded}
      onChange={(event) => setIsExpanded(event.detail.expanded)}
    >
      <Table
        items={items}
        columnDefinitions={columnDefinitions}
        variant="embedded"
        stripedRows
      />
    </ExpandableSection>
  );
}
