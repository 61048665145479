import {
  AccountingEventsGroup,
  CanonicalBusinessEvent,
  DigitalBusinessDocument,
  HealthState,
  HealthStatus,
} from "../types";
import { DlqInfo } from "../hooks/useFetchDlqInfo";

export function filterNonNoOpCBEs(
  cbes: CanonicalBusinessEvent[]
): CanonicalBusinessEvent[] {
  return cbes.filter((cbe) => !cbe.idempotenceId.includes("NONE"));
}

export function groupCBEsByVersion(cbes: CanonicalBusinessEvent[]) {
  return cbes.reduce((acc, cbe) => {
    const version = cbe.lockVersion;
    acc[version] = acc[version] || [];
    acc[version].push(cbe);
    return acc;
  }, {} as Record<number, CanonicalBusinessEvent[]>);
}

export const createVersionNumberArray = (
  cbeData: CanonicalBusinessEvent[]
): number[] =>
  Array.from(new Set(cbeData.map((cbe) => cbe.lockVersion))).sort(
    (a, b) => b - a
  );

function getLatestVersionCBEs(
  cbes: CanonicalBusinessEvent[]
): CanonicalBusinessEvent[] {
  if (!cbes.length) return [];

  const groupedCBEs = groupCBEsByVersion(cbes);
  const sortedVersions = createVersionNumberArray(cbes);
  const latestVersion = sortedVersions[0];
  return groupedCBEs[latestVersion] || [];
}

export function getCBEPublishStatus(cbe: CanonicalBusinessEvent) {
  return {
    isSuccess: cbe.publishStatus?.successFlag === true,
    isFailed: cbe.publishStatus?.successFlag === false,
    isUnpublished: cbe.publishStatus === undefined,
  };
}

export function determineCbeGroupHealth(
  cbes: CanonicalBusinessEvent[]
): HealthStatus {
  if (!cbes || cbes.length === 0) return "pending";

  const meaningfulCBEs = filterNonNoOpCBEs(cbes);
  if (meaningfulCBEs.length === 0) return "pending";

  const publishStatuses = meaningfulCBEs.map(getCBEPublishStatus);

  if (publishStatuses.some((status) => status.isFailed)) return "unhealthy";
  if (publishStatuses.some((status) => status.isUnpublished)) return "pending";
  if (publishStatuses.every((status) => status.isSuccess)) return "healthy";

  return "unknown";
}

export function determineOverallHealth(
  eventsReceived: boolean,
  latestCBEs: CanonicalBusinessEvent[],
  dlqInfo?: DlqInfo
): HealthStatus {
  // 1. No events means the order is not being processed at all
  if (!eventsReceived) return "unhealthy";

  // 2. Check health of latest version CBEs
  const cbeHealth = determineCbeGroupHealth(latestCBEs);
  // If latest CBEs are unhealthy, overall state is unhealthy regardless of DLQ
  if (cbeHealth === "unhealthy") return "unhealthy";

  // 3. For non-unhealthy CBE states, we need DLQ info to make final determination
  if (dlqInfo?.status === "error" || dlqInfo?.status === "loading") {
    return "unknown";
  }

  const hasDlqItems = dlqInfo?.dlqItems && dlqInfo.dlqItems.length > 0;

  // 4. No CBEs case: only unhealthy if there are DLQ items
  if (!latestCBEs.length) {
    return hasDlqItems ? "unhealthy" : "pending";
  }

  // 5. Final state: unhealthy if DLQ items exist, otherwise use CBE health
  return hasDlqItems ? "unhealthy" : cbeHealth;
}

export function calculateCompleteHealthState(
  events: (AccountingEventsGroup | DigitalBusinessDocument)[],
  cbes: CanonicalBusinessEvent[],
  dlqInfo: DlqInfo
): HealthState {
  const hasEvents = events.length > 0;
  const latestCBEs = getLatestVersionCBEs(cbes);
  const overallHealth = determineOverallHealth(hasEvents, latestCBEs, dlqInfo);

  return {
    overallHealth,
    eventsReceived: hasEvents,
    cbeStatus: {
      produced: latestCBEs.length > 0,
      published:
        latestCBEs.length > 0 &&
        latestCBEs.every((cbe) => getCBEPublishStatus(cbe).isSuccess),
    },
  };
}
