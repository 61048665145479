import { StatusIndicatorProps } from "@amzn/awsui-components-react/polaris/status-indicator/internal";
import { BalanceStatusType, CanonicalBusinessEvent } from "../types/cbes";

export function cbeWithoutPublishStatus(
  cbe: CanonicalBusinessEvent
): Omit<CanonicalBusinessEvent, "publishStatus"> {
  const { publishStatus, ...rest } = cbe;
  return rest;
}

export const getAccountingOperationColor = (operation: string) => {
  return operation === "POST"
    ? "text-status-success"
    : operation === "REVERSE"
    ? "text-status-info"
    : "inherit";
};

export function formatCurrencyValue(
  amount: number,
  currencyCode: string
): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "symbol",
  }).format(amount);
}

interface DeferralIds {
  revenueDeferralId?: string;
  royaltyDeferralId?: string;
}

export const getDeferralIds = (cbe: CanonicalBusinessEvent): DeferralIds => {
  let revenueDeferralId: string | undefined;
  let royaltyDeferralId: string | undefined;

  for (const record of Object.values(cbe.records)) {
    if (record["@type"] === "DeferralRecord" && record.content) {
      if (record.content.accountType === "digital_subscription_deferral_item") {
        revenueDeferralId = record.content.deferralId;
      } else if (record.content.accountType === "digital_royalty_pre_paid") {
        royaltyDeferralId = record.content.deferralId;
      }
    }

    // Fallback logic for older CBEs
    if (!revenueDeferralId) {
      if (record.clientAttributes && record.clientAttributes.deferral_id) {
        const deferralIdAttr = record.clientAttributes.deferral_id;
        if (
          typeof deferralIdAttr === "object" &&
          "stringValue" in deferralIdAttr &&
          typeof deferralIdAttr.stringValue === "string"
        ) {
          // For older CBEs, we'll only set the revenueDeferralId
          revenueDeferralId = deferralIdAttr.stringValue;
        }
      }
    }
  }

  return { revenueDeferralId, royaltyDeferralId };
};

export function getStatusIndicatorType(
  status: BalanceStatusType
): StatusIndicatorProps.Type {
  switch (status) {
    case "Skipped":
      return "in-progress";
    case "Balanced":
      return "success";
    case "Unbalanced":
      return "error";
  }
}
