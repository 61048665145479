import { Badge, SpaceBetween } from "@amzn/awsui-components-react";
import { getBadgeColour } from "../../helpers/errorUtils";

interface QueueSummaryProps {
  summary: { [key: string]: number };
}

export function QueueSummary({ summary }: QueueSummaryProps) {
  return (
    <SpaceBetween direction="horizontal" size="xs">
      {Object.entries(summary).map(([queue, count]) => (
        <SpaceBetween direction="horizontal" size="xxs" key={queue}>
          <span>{count}x</span>
          <Badge color={getBadgeColour(queue)}>{queue}</Badge>
        </SpaceBetween>
      ))}
    </SpaceBetween>
  );
}
