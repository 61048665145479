import {
  Box,
  ExpandableSection,
  Grid,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import {
  CanonicalBusinessEvent,
  GroupedRecordsInterface,
} from "../../../types/cbes";
import { calculateDebitCreditTotals } from "../../../helpers/cbeBalanceUtils";
import { formatCurrencyValue } from "../../../helpers/cbeSummaryUtils";
import { RecordCard } from "../RecordCard";

export function GroupedRecords({
  groupedRecords,
  expandedSections,
  handleSectionChange,
  stage,
  marketplaceId,
  cbe,
}: {
  groupedRecords: GroupedRecordsInterface;
  expandedSections: Record<string, boolean>;
  handleSectionChange: (
    section: string
  ) => (event: { detail: { expanded: boolean } }) => void;
  stage: string;
  marketplaceId: string;
  cbe: CanonicalBusinessEvent;
}) {
  return (
    <>
      {Object.entries(groupedRecords).map(([group, { debits, credits }]) => {
        const totals = calculateDebitCreditTotals(debits, credits);
        const description = Object.entries(totals)
          .map(
            ([currencyCode, { debits, credits }]) =>
              `${currencyCode}: Debits: ${formatCurrencyValue(
                debits,
                currencyCode
              )}, Credits: ${formatCurrencyValue(credits, currencyCode)}`
          )
          .join(" | ");

        return (
          <ExpandableSection
            key={group}
            expanded={expandedSections[group]}
            onChange={handleSectionChange(group)}
            variant="footer"
            header={
              <Header variant="h3" description={description}>
                Financial Component Group: {group}
              </Header>
            }
          >
            <Grid
              gridDefinition={[
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
              ]}
            >
              <Box>
                <Header variant="h3">Debits</Header>
                <SpaceBetween size="m">
                  {debits.map((debit) => (
                    <RecordCard
                      item={debit}
                      cbe={cbe}
                      stage={stage}
                      marketplaceId={marketplaceId}
                    />
                  ))}
                </SpaceBetween>
              </Box>
              <Box>
                <Header variant="h3">Credits</Header>
                <SpaceBetween size="m">
                  {credits.map((credit) => (
                    <RecordCard
                      item={credit}
                      cbe={cbe}
                      stage={stage}
                      marketplaceId={marketplaceId}
                    />
                  ))}
                </SpaceBetween>
              </Box>
            </Grid>
          </ExpandableSection>
        );
      })}
    </>
  );
}
