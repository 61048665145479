import {
  Box,
  ExpandableSection,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import ReactJson from "react-json-view";
import { DigitalCostDocument } from "../../../types";

interface DigitalCostDocumentViewProps {
  dcd: DigitalCostDocument;
  jsonViewProps: any;
}

export function DigitalCostDocumentView({
  dcd,
  jsonViewProps,
}: DigitalCostDocumentViewProps) {
  return (
    <SpaceBetween size="l">
      <ExpandableSection
        header={
          <Box variant="h4" margin={{ top: "xxs" }}>
            {dcd.id} (Digital Business Document)
          </Box>
        }
        variant="container"
      >
        <ReactJson src={dcd.body} {...jsonViewProps} />
      </ExpandableSection>
    </SpaceBetween>
  );
}
