import { StepsProps } from "@amzn/awsui-components-react/polaris/steps/interfaces";
import { useHealthState } from "../contexes/HealthStateContext";
import { DlqStatus } from "../components/dlq/DlqStatusIndicator";

export function useHealthStatus(dlqStatus: DlqStatus) {
  const state = useHealthState();
  const { eventsReceived, cbeStatus } = state;

  const getCBEsPublishedStatus = (): StepsProps.Status => {
    if (cbeStatus.published) {
      return "success";
    }

    if (!cbeStatus.produced) {
      return "pending"; // No CBEs produced yet
    }

    return "error"; // CBEs produced but not published successfully
  };

  // Translate DLQ status into a status accepted by the step component
  const getDlqCheckStatus = (): StepsProps.Status => {
    switch (dlqStatus) {
      case "notInDlq":
        return "success";
      case "inDlq":
        return "error";
      case "loading":
        return "loading";
      case "error":
      case "unknown":
      default:
        return "error";
    }
  };

  const getHealthSteps = (): StepsProps.Step[] => [
    {
      status: eventsReceived ? "success" : "error",
      header: "Events Received",
    },
    {
      status: cbeStatus.produced ? "success" : "error",
      header: "CBEs Produced",
    },
    {
      status: getCBEsPublishedStatus(),
      header: "CBEs Published",
    },
    {
      status: getDlqCheckStatus(),
      header: "DLQ Check",
    },
  ];

  return {
    healthStatus: state.overallHealth,
    steps: getHealthSteps(),
    state,
  };
}
