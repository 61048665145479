import ReactJson from "react-json-view";
import { useContext, useState } from "react";
import {
  AccountingEventsGroup,
  DigitalBusinessDocument,
  DigitalCostDocument,
  JsonViewProps,
} from "../../types";
import DigitalBusinessDocumentView from "./document/DigitalBusinessDocumentView";
import { DigitalCostDocumentView } from "./document/DigitalCostDocumentView";
import { AccountingEventsView } from "./AccountingEventsView";
import DarkModeContext from "../../contexes/DarkModeContext";
import { BASE_16_CUSTOM_DARK_THEME } from "../../constants";
import {
  isDigitalBusinessDocument,
  isDigitalCostDocument,
} from "../../helpers/typeChecks";
import { JsonModal } from "../common/JsonModal";

interface EventResultsProps {
  eventData:
    | AccountingEventsGroup
    | DigitalBusinessDocument
    | DigitalCostDocument;
}

export default function EventResults({ eventData }: EventResultsProps) {
  const darkMode = useContext(DarkModeContext);
  const [isJsonModalVisible, setIsJsonModalVisible] = useState(false);

  const jsonViewProps: JsonViewProps = {
    displayDataTypes: false,
    collapsed: 3,
    theme: darkMode ? BASE_16_CUSTOM_DARK_THEME : "rjv-default",
  } as const;

  if ("accountingEvents" in eventData) {
    return (
      <AccountingEventsView
        accountingEvents={eventData.accountingEvents}
        jsonViewProps={jsonViewProps}
      />
    );
  }

  if (isDigitalBusinessDocument(eventData)) {
    return (
      <>
        <DigitalBusinessDocumentView
          dbd={eventData}
          modalHandler={() => setIsJsonModalVisible(true)}
        />
        <JsonModal
          title={`${eventData.id} (DigitalBusinessDocument)`}
          content={eventData.body}
          visible={isJsonModalVisible}
          onDismiss={() => setIsJsonModalVisible(false)}
        />
      </>
    );
  }

  if (isDigitalCostDocument(eventData)) {
    return (
      <DigitalCostDocumentView dcd={eventData} jsonViewProps={jsonViewProps} />
    );
  }

  return <ReactJson src={eventData} {...jsonViewProps} />;
}
