import { HealthIndicatorEntry, HealthStatus } from "../types/health";

const healthIndicator: Record<HealthStatus, HealthIndicatorEntry> = {
  healthy: {
    type: "success",
    color: "text-status-success",
    message: "This transaction is healthy",
  },
  unhealthy: {
    type: "error",
    color: "text-status-error",
    message: "This transaction has issues",
  },
  pending: {
    type: "pending",
    color: "text-status-inactive",
    message: "This transaction is in progress",
  },
  unknown: {
    type: "in-progress",
    color: "text-status-inactive",
    message: "This transaction is unknown",
  },
};

export default healthIndicator;
