import { useContext } from "react";
import {
  Badge,
  Button,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from "@amzn/awsui-components-react";
import { StageContext } from "../../contexes/OrderIdContext";
import { getDlqDateRange, getDlqLogGroup } from "../../helpers/logUtils";
import { getBadgeColour } from "../../helpers/errorUtils";
import { DlqItem } from "../../types";
import LogButtons from "../common/LogButtons";

interface DlqTableProps {
  items: DlqItem[];
  sortingColumn: any;
  sortingDescending: boolean;
  onSortingChange: (event: any) => void;
  filteringText: string;
  onFilteringChange: (text: string) => void;
  currentPageIndex: number;
  onPageChange: (pageIndex: number) => void;
  pageSize: number;
  onItemSelect: (item: DlqItem) => void;
  filteredItemsCount: number;
}

export function DlqTable({
  items,
  sortingColumn,
  sortingDescending,
  onSortingChange,
  filteringText,
  onFilteringChange,
  currentPageIndex,
  onPageChange,
  pageSize,
  onItemSelect,
  filteredItemsCount,
}: DlqTableProps) {
  const stage = useContext(StageContext);

  const columnDefinitions = [
    {
      id: "eventsourcekey",
      header: "Queue",
      cell: (item: DlqItem) => (
        <SpaceBetween direction="horizontal" size="xs">
          <Badge color={getBadgeColour(item.eventsourcekey)}>
            {item.eventsourcekey}
          </Badge>
          {item.is_malformed_json && <Badge color="red">Malformed JSON</Badge>}
        </SpaceBetween>
      ),
      sortingField: "eventsourcekey",
    },
    {
      id: "pk",
      header: "DLQ Table PK",
      cell: (item: DlqItem) => item.pk,
      sortingField: "pk",
    },
    {
      id: "timestamp",
      header: "Timestamp",
      cell: (item: DlqItem) => item.timestamp,
      sortingField: "timestamp",
    },
    {
      id: "rawJson",
      header: "Raw JSON",
      cell: (item: DlqItem) => (
        <Button onClick={() => onItemSelect(item)}>
          {item.is_malformed_json ? "View Raw Message" : "View Raw JSON"}
        </Button>
      ),
    },
    {
      id: "logs",
      header: "Logs",
      cell: (item: DlqItem) => {
        const logGroup = getDlqLogGroup(item.eventsourcekey, stage);
        const [startTime, endTime] = getDlqDateRange(item.timestamp);
        return (
          <LogButtons
            identifier={item.pk}
            stage={stage}
            logGroups={[logGroup]}
            dateRange={[startTime, endTime]}
            isDlq
          />
        );
      },
    },
  ];

  return (
    <Table
      columnDefinitions={columnDefinitions}
      items={items}
      sortingColumn={sortingColumn}
      sortingDescending={sortingDescending}
      onSortingChange={onSortingChange}
      filter={
        <TextFilter
          filteringText={filteringText}
          filteringPlaceholder="Search DLQ items"
          filteringAriaLabel="Filter DLQ items"
          onChange={({ detail }) => onFilteringChange(detail.filteringText)}
        />
      }
      header={<Header counter={`(${filteredItemsCount})`}>DLQ Items</Header>}
      pagination={
        <Pagination
          currentPageIndex={currentPageIndex}
          onChange={({ detail }) => onPageChange(detail.currentPageIndex)}
          pagesCount={Math.ceil(filteredItemsCount / pageSize)}
        />
      }
    />
  );
}
