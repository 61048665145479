import {
  ExpandableSection,
  Header,
  SpaceBetween,
  TextContent,
} from "@amzn/awsui-components-react";

interface ExpandableDbdSectionProps {
  title: string;
  description?: string | React.ReactNode;
  expanded: boolean;
  onChange: (event: { detail: { expanded: boolean } }) => void;
  children?: React.ReactNode;
  variant?: "container" | "footer" | "default";
  isEmpty?: boolean;
  emptyMessage?: string;
}

export function ExpandableDbdSection({
  title,
  description,
  expanded,
  onChange,
  children,
  variant = "container",
  isEmpty = false,
  emptyMessage = "No data available",
}: ExpandableDbdSectionProps) {
  return (
    <ExpandableSection
      variant={variant}
      expanded={expanded}
      onChange={onChange}
      header={
        <Header variant="h3">
          {title}
          {description && (
            <>
              <br />
              <TextContent>
                <small>{description}</small>
              </TextContent>
            </>
          )}
        </Header>
      }
    >
      <SpaceBetween size="l">
        {isEmpty ? (
          <TextContent>
            <small>{emptyMessage}</small>
          </TextContent>
        ) : (
          children
        )}
      </SpaceBetween>
    </ExpandableSection>
  );
}
