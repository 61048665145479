import {
  Button,
  CopyToClipboard,
  SpaceBetween,
} from "@amzn/awsui-components-react";

interface ActionButtonsProps {
  modalHandler: () => void;
  toggleAllSections: (expand: boolean) => void;
  copyConfig?: {
    textToCopy: string;
    buttonText: string;
    successText: string;
    errorText: string;
  };
}

export function ActionButtons({
  modalHandler,
  toggleAllSections,
  copyConfig,
}: ActionButtonsProps) {
  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button onClick={modalHandler} iconName="command-prompt">
        View JSON
      </Button>
      <Button onClick={() => toggleAllSections(true)} iconName="zoom-in">
        Expand All
      </Button>
      <Button onClick={() => toggleAllSections(false)} iconName="zoom-out">
        Collapse All
      </Button>
      {copyConfig && (
        <CopyToClipboard
          textToCopy={copyConfig.textToCopy}
          copyButtonText={copyConfig.buttonText}
          copySuccessText={copyConfig.successText}
          copyErrorText={copyConfig.errorText}
        />
      )}
    </SpaceBetween>
  );
}
