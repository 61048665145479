import {
  Badge,
  Container,
  KeyValuePairs,
  KeyValuePairsProps,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import { DigitalBusinessDocument } from "../../../types";
import {
  getFulfillmentStatus,
  getFulfillmentText,
  getPaymentStatus,
  getPaymentStatusText,
  getRefundStatus,
  hasCostRefunds,
  hasCosts,
  hasPromotions,
  screamingSnakeToTitleCase,
} from "../../../helpers/dbdUtils";
import Item = KeyValuePairsProps.Item;

interface SummarySectionProps {
  dbd: DigitalBusinessDocument;
}

export function SummarySection({ dbd }: SummarySectionProps) {
  const { body } = dbd.body;

  const getPaymentMethod = () => {
    const paymentId = Object.keys(body?.payments || {})[0];
    const payment = body?.payments?.[paymentId];
    return payment?.paymentMethods?.[paymentId];
  };

  const getFirstProduct = () => {
    return Object.values(body?.products || {})[0];
  };
  const method = getPaymentMethod();
  const firstProduct = getFirstProduct();
  const isManualRedrive =
    dbd.body?.metadata?.internalAttributes?.MANUAL_REDRIVE === true;

  const items: Item[] = [
    {
      label: "Created",
      value: (
        <SpaceBetween direction="horizontal" size="xs">
          {dbd.creationDate
            ? new Date(dbd.creationDate).toLocaleString()
            : "Unknown"}
          {isManualRedrive && <Badge color="grey">Manual Redrive</Badge>}
        </SpaceBetween>
      ),
    },
    {
      label: "Fulfillment Type",
      value: screamingSnakeToTitleCase(
        dbd.body.metadata.internalAttributes?.FULFILLMENT_TYPE || "IMMEDIATE"
      ),
    },
    {
      label: "Payment Status",
      value: method ? (
        <StatusIndicator type={getPaymentStatus(method)}>
          {getPaymentStatusText(method)}
        </StatusIndicator>
      ) : (
        <StatusIndicator type="stopped">No Payment Data</StatusIndicator>
      ),
    },
    {
      label: "Refund Status",
      value: (() => {
        const status = getRefundStatus(method);
        return (
          <StatusIndicator type={status.type}>{status.text}</StatusIndicator>
        );
      })(),
    },
    {
      label: "Fulfillment Status",
      value: firstProduct ? (
        <StatusIndicator type={getFulfillmentStatus(firstProduct)}>
          {getFulfillmentText(firstProduct)}
        </StatusIndicator>
      ) : (
        <StatusIndicator type="stopped">No Product Data</StatusIndicator>
      ),
    },
    {
      label: "Promotions",
      value: firstProduct ? (
        <StatusIndicator
          type={hasPromotions(firstProduct.bills) ? "success" : "stopped"}
        >
          {hasPromotions(firstProduct.bills)
            ? "Has Promotions"
            : "No Promotions"}
        </StatusIndicator>
      ) : (
        <StatusIndicator type="stopped">No Product Data</StatusIndicator>
      ),
    },
    {
      label: "Cost Status",
      value: firstProduct ? (
        <StatusIndicator type={hasCosts(firstProduct) ? "success" : "stopped"}>
          {hasCosts(firstProduct) ? "Has Cost" : "No Cost"}
        </StatusIndicator>
      ) : (
        <StatusIndicator type="stopped">No Product Data</StatusIndicator>
      ),
    },
    {
      label: "Cost Refund Status",
      value: firstProduct ? (
        <StatusIndicator
          type={hasCostRefunds(firstProduct.costs) ? "success" : "stopped"}
        >
          {hasCostRefunds(firstProduct.costs)
            ? "Has Cost Refund"
            : "No Cost Refund"}
        </StatusIndicator>
      ) : (
        <StatusIndicator type="stopped">No Product Data</StatusIndicator>
      ),
    },
  ];

  return (
    <Container>
      <KeyValuePairs columns={2} items={items} />
    </Container>
  );
}
