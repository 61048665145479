import {
  Button,
  Header,
  Link,
  Modal,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react";

import { parseErrorDetails } from "../../helpers/errorUtils";
import { CanonicalBusinessEvent, ErrorDetails } from "../../types";
import { usePublishStatus } from "../../hooks/usePublishStatus";
import ModalFooter from "../common/ModalFooter";
import ErrorContentRenderer from "./ErrorContentRenderer";
import { getCBEPublishStatus } from "../../helpers/healthUtils";

interface PublishStatusProps {
  cbe: CanonicalBusinessEvent;
}

export default function PublishStatusComponent({ cbe }: PublishStatusProps) {
  const {
    isModalVisible,
    expandAll,
    handleModalOpen,
    handleModalClose,
    handleExpandAll,
    getCbeLinks,
  } = usePublishStatus(cbe);

  function renderSuccessLinks() {
    const cbeLinks = getCbeLinks();
    if (cbeLinks.length === 1) {
      return (
        <Link
          key="success-link"
          external
          externalIconAriaLabel="Opens in a new tab"
          href={cbeLinks[0].href}
          data-testid="FlashLink"
        >
          <StatusIndicator type="success">Publish Success</StatusIndicator>
        </Link>
      );
    }
    return (
      <SpaceBetween direction="horizontal" size="xs">
        <StatusIndicator type="success">Publish Success</StatusIndicator>
        {cbeLinks.map((link, index) => (
          <Link
            key={`success-link-${index}`}
            external
            externalIconAriaLabel="Opens in a new tab"
            href={link.href}
            data-testid={`FlashLink-${index}`}
          >
            {link.label}
          </Link>
        ))}
      </SpaceBetween>
    );
  }

  if (cbe.idempotenceId.includes("NONE")) {
    return <StatusIndicator type="info">NoOp</StatusIndicator>;
  }

  const publishStatus = getCBEPublishStatus(cbe);

  if (publishStatus.isUnpublished) {
    return (
      <StatusIndicator type="pending">
        Status Unknown/Pending Publish
      </StatusIndicator>
    );
  }

  if (publishStatus.isSuccess) {
    return renderSuccessLinks();
  }

  const errorDetails: ErrorDetails = parseErrorDetails(
    cbe.publishStatus?.stackTrace || "No error details",
    cbe.destination
  );

  return (
    <>
      <Button
        onClick={handleModalOpen}
        variant="inline-link"
        className="publish-status-button"
      >
        <StatusIndicator type="error">Error Publishing</StatusIndicator>
      </Button>
      <Modal
        className="error-modal"
        onDismiss={handleModalClose}
        visible={isModalVisible}
        closeAriaLabel="Close modal"
        size="max"
        footer={
          <ModalFooter
            stackTrace={errorDetails.stackTrace}
            inputRequestUrl={errorDetails.inputRequestUrl}
            onClose={handleModalClose}
            onExpandToggle={handleExpandAll}
            expandAll={expandAll}
          />
        }
        header={
          <Header variant="h2">
            Error Type: {errorDetails.errorType || "Unknown"}
          </Header>
        }
      >
        <ErrorContentRenderer
          errorDetails={errorDetails}
          isModalVisible={isModalVisible}
          expandAll={expandAll}
        />
      </Modal>
    </>
  );
}
