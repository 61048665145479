import { useCallback, useContext, useEffect, useState } from "react";
import { CanonicalBusinessEvent } from "../types/cbes";
import { StageContext } from "../contexes/OrderIdContext";
import { useMarketplaceId } from "../contexes/MarketplaceIdContext";
import { useDeferralId } from "../contexes/DeferralIdContext";
import { getDeferralIds } from "../helpers/cbeSummaryUtils";
import { createFlashUILink, createRaceLink } from "../helpers/linkUtils";

interface CbeLink {
  label: string;
  href: string;
}

export function usePublishStatus(cbe: CanonicalBusinessEvent) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [expandAll, setExpandAll] = useState(true);
  const stage = useContext(StageContext);
  const { marketplaceId } = useMarketplaceId();
  const { setDeferralIds } = useDeferralId();

  const handleModalOpen = useCallback(() => setIsModalVisible(true), []);
  const handleModalClose = useCallback(() => setIsModalVisible(false), []);
  const handleExpandAll = useCallback(() => setExpandAll((prev) => !prev), []);

  useEffect(() => {
    const { revenueDeferralId, royaltyDeferralId } = getDeferralIds(cbe);
    setDeferralIds((prevIds) => {
      if (
        (revenueDeferralId &&
          revenueDeferralId !== prevIds.revenueDeferralId) ||
        (royaltyDeferralId && royaltyDeferralId !== prevIds.royaltyDeferralId)
      ) {
        return {
          revenueDeferralId: revenueDeferralId || prevIds.revenueDeferralId,
          royaltyDeferralId: royaltyDeferralId || prevIds.royaltyDeferralId,
        };
      }
      return prevIds;
    });
  }, [cbe, setDeferralIds]);

  const getCbeLinks = useCallback((): CbeLink[] => {
    if (cbe.destination === "FLARE") {
      return [
        {
          label: "Flash UI",
          href: createFlashUILink(marketplaceId, cbe.idempotenceId, stage),
        },
      ];
    }

    if (cbe.destination === "RACE") {
      const { revenueDeferralId, royaltyDeferralId } = getDeferralIds(cbe);

      if (
        revenueDeferralId &&
        royaltyDeferralId &&
        revenueDeferralId !== royaltyDeferralId
      ) {
        return [
          {
            label: "Revenue Deferral",
            href: createRaceLink(marketplaceId, revenueDeferralId, stage),
          },
          {
            label: "Royalty Deferral",
            href: createRaceLink(marketplaceId, royaltyDeferralId, stage),
          },
        ];
      }

      if (revenueDeferralId || royaltyDeferralId) {
        return [
          {
            label: "Deferral",
            href: createRaceLink(
              marketplaceId,
              revenueDeferralId || royaltyDeferralId || "",
              stage
            ),
          },
        ];
      }

      return [
        {
          label: "Order ID",
          href: createRaceLink(marketplaceId, cbe.orderId, stage),
        },
      ];
    }

    return [{ label: "Unknown", href: "#" }];
  }, [cbe, marketplaceId, stage]);

  return {
    isModalVisible,
    expandAll,
    handleModalOpen,
    handleModalClose,
    handleExpandAll,
    getCbeLinks,
  };
}
