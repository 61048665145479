import { useEffect, useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { compareDates } from "../config/searchTableConfig";
import { SearchHistoryItem } from "../types";

export function useSearchHistory() {
  const [allItems, setAllItems] = useState<SearchHistoryItem[]>([]);

  useEffect(() => {
    const storedOrderIds = JSON.parse(
      localStorage.getItem("orderIds") || "[]"
    ) as SearchHistoryItem[];
    setAllItems(storedOrderIds);
  }, []);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(allItems, {
      filtering: {
        filteringFunction: (item, filteringText) => {
          const filterLower = filteringText.toLowerCase();
          const derivedStatus = item.status || "unknown";
          return (
            item.orderId?.toLowerCase().includes(filterLower) ||
            item.countryCode?.toLowerCase().includes(filterLower) ||
            item.stage?.toLowerCase().includes(filterLower) ||
            derivedStatus.toLowerCase().includes(filterLower)
          );
        },
      },
      pagination: { pageSize: 15 },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: "date",
            sortingComparator: (a: SearchHistoryItem, b: SearchHistoryItem) =>
              compareDates(a.date, b.date),
          },
          isDescending: true,
        },
      },
      selection: {},
    });

  const deleteSelectedItems = () => {
    const selectedItems = collectionProps.selectedItems || [];
    const updatedItems = allItems.filter(
      (item) =>
        !selectedItems.some(
          (selected) =>
            selected.orderId === item.orderId && selected.stage === item.stage
        )
    );
    setAllItems(updatedItems);
    localStorage.setItem("orderIds", JSON.stringify(updatedItems));
  };

  return {
    items,
    actions,
    collectionProps,
    filterProps,
    paginationProps,
    deleteSelectedItems,
  };
}
