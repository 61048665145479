import { StatusIndicatorProps } from "@amzn/awsui-components-react/polaris/status-indicator/internal";
import { DBDAmount, PaymentMethod, ProductDetails, Refund } from "../types";

export const getPaymentStatus = (method: PaymentMethod) => {
  if (method.obligation.state.complete) return "success";
  if (method.obligation.state.failed) return "error";
  return "in-progress";
};

export const getPaymentStatusText = (method: PaymentMethod) => {
  if (method.obligation.state.complete)
    return `Paid (${method.paymentProvider.amazonManagedPayment.name})`;
  if (method.obligation.state.failed)
    return `Failed (${method.paymentProvider.amazonManagedPayment.name})`;
  return `Pending (${method.paymentProvider.amazonManagedPayment.name})`;
};

export const getFulfillmentStatus = (product: ProductDetails) => {
  if (product.details.fulfillment.cancellation) return "error";
  if (product.details.fulfillment.state.complete) return "success";
  return "in-progress";
};

export const getFulfillmentText = (product: ProductDetails) => {
  if (product.details.fulfillment.cancellation)
    return `Cancelled (${product.details.fulfillment.cancellation.reason})`;
  if (product.details.fulfillment.state.complete) return "Fulfilled";
  return "Pending";
};

export const getProductFulfillmentStatus = (
  details: ProductDetails["details"]
) => {
  if (details.fulfillment.cancellation) {
    return "Cancelled";
  }
  return details.fulfillment.state.complete ? "Fulfilled" : "Pending";
};

export const calculateTotalAmount = (amount: DBDAmount) =>
  parseFloat(amount.customerTotalAmountWithoutTax.value) +
  parseFloat(amount.customerTaxAmount.value);

export const calculatePromoTotal = (promotions: Record<string, any>) =>
  Object.values(promotions).reduce(
    (sum, promo) =>
      sum + parseFloat(promo.amount.customerTotalAmountWithoutTax.value),
    0
  );

export const hasRefunds = (method: PaymentMethod) =>
  Object.keys(method.obligation.refunds).length > 0;

export const getIndividualRefundStatus = (
  refund: Refund
): {
  type: StatusIndicatorProps.Type;
  text: string;
} | null => {
  if (!refund.state) {
    return null;
  }

  if (refund.state.failed) {
    return {
      type: "error",
      text: `Failed - ${refund.state.failed.reason}`,
    };
  }
  if (refund.state.complete) {
    return {
      type: "success",
      text: "Complete",
    };
  }
  if (refund.state.promised) {
    return {
      type: "pending",
      text: "Pending",
    };
  }
  return {
    type: "in-progress",
    text: "Processing",
  };
};
export const getRefundStatus = (
  method?: PaymentMethod
): {
  type: StatusIndicatorProps.Type;
  text: string;
} => {
  if (!method) {
    return { type: "stopped", text: "No Payment Data" };
  }

  if (!hasRefunds(method)) {
    return { type: "stopped", text: "No Refund" };
  }

  const refunds = Object.values(method.obligation.refunds);
  const statuses = refunds
    .map(getIndividualRefundStatus)
    .filter((status): status is NonNullable<typeof status> => status !== null);

  const hasPending = statuses.some((status) => status.type === "pending");
  const hasSuccess = statuses.some((status) => status.type === "success");
  const hasError = statuses.some((status) => status.type === "error");

  if (hasPending) {
    return { type: "pending", text: "Refund Pending" };
  }
  if (hasSuccess) {
    return { type: "success", text: "Refunded" };
  }
  if (hasError) {
    return { type: "error", text: "Refund Failed" };
  }

  return { type: "in-progress", text: "Processing" };
};

export const hasPromotions = (bills: Record<string, any>) =>
  Object.values(bills).some((bill) => Object.keys(bill.promotions).length > 0);

export const hasCosts = (product: ProductDetails) =>
  Object.keys(product.costs).length > 0;

export const hasCostRefunds = (costs: ProductDetails["costs"]) =>
  Object.values(costs).some(
    (cost) => cost.refunds && Object.keys(cost.refunds).length > 0
  );

export const formatDateTime = (date?: string | Date) => {
  if (!date) return "";
  const dateObj = typeof date === "string" ? new Date(date) : date;
  return dateObj.toLocaleString();
};

export const formatCurrency = (
  value: string | number,
  currencyCode: string
) => {
  const numValue = typeof value === "string" ? parseFloat(value) : value;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  }).format(numValue);
};

export const getStatusType = (
  complete?: { transactionId: string; at: string } | boolean,
  failed?: { at: string; reason: string; decline: string } | boolean
): "success" | "error" | "in-progress" => {
  if (complete) return "success";
  if (failed) return "error";
  return "in-progress";
};

export const screamingSnakeToTitleCase = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (c) => c.toUpperCase());
};
