import { ExpandableSection, SpaceBetween } from "@amzn/awsui-components-react";
import CbeEntry from "./CbeEntry";
import CbeHealthStatusDisplay from "./CbeHealthStatusDisplay";
import { CanonicalBusinessEvent } from "../../types";

interface Props {
  cbeData: CanonicalBusinessEvent[];
}

function CbeEntryList({ events }: { events: CanonicalBusinessEvent[] }) {
  return (
    <SpaceBetween size="xl">
      {events.map((cbe, index) => (
        <CbeEntry
          key={`${cbe.pk}-${cbe.idempotenceVersion}-${index}`}
          cbe={cbe}
        />
      ))}
    </SpaceBetween>
  );
}

export default function CbeResults({ cbeData }: Props) {
  const destinationGroups = cbeData.reduce((acc, cbe) => {
    const { destination } = cbe;
    if (!acc[destination]) {
      acc[destination] = [];
    }
    acc[destination].push(cbe);
    return acc;
  }, {} as Record<string, CanonicalBusinessEvent[]>);

  const destinations = Object.keys(destinationGroups).sort();

  if (destinations.length <= 1) {
    return <CbeEntryList events={cbeData} />;
  }

  return (
    <SpaceBetween size="xl">
      {destinations.map((destination) => (
        <ExpandableSection
          key={destination}
          defaultExpanded
          headerText={
            <CbeHealthStatusDisplay
              name="Destination:"
              value={destination}
              events={destinationGroups[destination]}
            />
          }
          variant="footer"
        >
          <CbeEntryList events={destinationGroups[destination]} />
        </ExpandableSection>
      ))}
    </SpaceBetween>
  );
}
