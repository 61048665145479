import { CanonicalBusinessEvent, CBERecord } from "../types";

function getFlareConfigDomain(stage: string): string {
  const lowercaseStage = stage.toLowerCase();
  return lowercaseStage === "gamma"
    ? "uat"
    : lowercaseStage === "prod"
    ? "prod"
    : "devo";
}

export default function createFlareConfigLink(
  cbe: CanonicalBusinessEvent,
  record: CBERecord,
  stage: string
): string {
  const baseUrl = "https://flare.aka.amazon.com/flash/traffic_search";
  const domain = getFlareConfigDomain(stage);
  const financialComponentGroup =
    record.content?.financialComponentGroup ??
    record.flareConfigFinancialComponentGroup ??
    "Unknown";
  const financialComponent =
    record.content?.financialComponent ??
    record.flareConfigFinancialComponent ??
    "Unknown";

  const params = new URLSearchParams({
    aggregationAttributes:
      "business_namespace+business_country_code+business_activity+gl_line_description",
    domain,
    executionContextKey: "processing",
    query: `business_namespace:${cbe.businessNamespace} financial_component_group:${financialComponentGroup} financial_component:${financialComponent} business_activity:${cbe.businessActivity} business_country_code:${cbe.businessCountryCode}`,
  });

  return `${baseUrl}?${params.toString()}`;
}
