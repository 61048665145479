import { useCallback, useMemo, useState } from "react";
import {
  NonCancelableCustomEvent,
  TableProps,
} from "@amzn/awsui-components-react";
import { DlqItem } from "../types";

export function useDlqTable(dlqItems: DlqItem[]) {
  const [selectedItem, setSelectedItem] = useState<DlqItem | null>(null);
  const [sortingColumn, setSortingColumn] = useState<
    TableProps.SortingColumn<DlqItem>
  >({
    sortingField: "timestamp",
  });
  const [sortingDescending, setSortingDescending] = useState(true);
  const [filteringText, setFilteringText] = useState("");
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [pageSize] = useState(20);

  const queueSummary = useMemo(() => {
    const summary: { [key: string]: number } = {};
    dlqItems.forEach((item) => {
      summary[item.eventsourcekey] = (summary[item.eventsourcekey] || 0) + 1;
    });
    return summary;
  }, [dlqItems]);

  const filteredItems = useMemo(
    () =>
      dlqItems.filter((item) => {
        const searchText = filteringText.toLowerCase();
        const messageString = item.is_malformed_json
          ? (item.message as string)
          : JSON.stringify(item.message);

        return (
          Object.entries(item)
            .filter(([key]) => key !== "message")
            .some(([, value]) =>
              String(value).toLowerCase().includes(searchText)
            ) || messageString.toLowerCase().includes(searchText)
        );
      }),
    [dlqItems, filteringText]
  );

  const sortedItems = useMemo(
    () =>
      [...filteredItems].sort((a, b) => {
        const key = sortingColumn.sortingField as keyof DlqItem;
        if (a[key] < b[key]) return sortingDescending ? 1 : -1;
        if (a[key] > b[key]) return sortingDescending ? -1 : 1;
        return 0;
      }),
    [filteredItems, sortingColumn, sortingDescending]
  );

  const paginatedItems = useMemo(
    () =>
      sortedItems.slice(
        (currentPageIndex - 1) * pageSize,
        currentPageIndex * pageSize
      ),
    [sortedItems, currentPageIndex, pageSize]
  );

  const handleSortingChange = useCallback(
    (event: NonCancelableCustomEvent<TableProps.SortingState<DlqItem>>) => {
      setSortingColumn(event.detail.sortingColumn);
      setSortingDescending(event.detail.isDescending ?? false);
    },
    []
  );

  return {
    selectedItem,
    setSelectedItem,
    sortingColumn,
    sortingDescending,
    filteringText,
    setFilteringText,
    currentPageIndex,
    setCurrentPageIndex,
    pageSize,
    queueSummary,
    filteredItems,
    paginatedItems,
    handleSortingChange,
  };
}
