import { useContext } from "react";
import { CanonicalBusinessEvent, CBERecord } from "../types/cbes";
import { StageContext } from "../contexes/OrderIdContext";
import { useMarketplaceId } from "../contexes/MarketplaceIdContext";
import {
  groupRecordsByEntryType,
  shouldSkipBalanceCheck,
} from "../helpers/cbeBalanceUtils";
import { useExpandableSections } from "./useExpandableSections";

export function useCbeSummary(cbe: CanonicalBusinessEvent) {
  const stage = useContext(StageContext);
  const { marketplaceId } = useMarketplaceId();

  const records: CBERecord[] = Array.isArray(cbe.records)
    ? cbe.records
    : Object.values(cbe.records || {});

  const skipResult = shouldSkipBalanceCheck(records, cbe);
  const groupedRecords = groupRecordsByEntryType(records);

  const initialSections = Object.keys(groupedRecords).reduce(
    (acc, group) => ({
      ...acc,
      [group]: true,
    }),
    {}
  );

  const { expandedSections, toggleAllSections, handleSectionChange } =
    useExpandableSections(initialSections);

  return {
    stage,
    marketplaceId,
    records,
    skipResult,
    groupedRecords,
    expandedSections,
    toggleAllSections,
    handleSectionChange,
  };
}
