import { Table } from "@amzn/awsui-components-react";
import { GroupedRecordsInterface } from "../../../types/cbes";
import { useBalanceDetails } from "../../../hooks/useBalanceDetails";

interface BalanceDetailsProps {
  groupedRecords: GroupedRecordsInterface;
}

export function BalanceDetails({ groupedRecords }: BalanceDetailsProps) {
  const { items } = useBalanceDetails(groupedRecords);

  return (
    <Table
      columnDefinitions={[
        { header: "Group", cell: (item) => item.group },
        { header: "Debits", cell: (item) => item.debits },
        { header: "Credits", cell: (item) => item.credits },
      ]}
      items={items}
      variant="embedded"
      stripedRows
    />
  );
}
