import {CbesApi, DlqApi} from "./generated-src";
import {getAppSetting} from "../config/AppSettings";
import {Configuration} from "./generated-src/configuration";

export function DetailsApiFactory() {
  const apiBasePath = `https://${getAppSetting("apiUrl")}/jwt`;
  return new CbesApi(new Configuration({basePath: apiBasePath}));
}

export function DlqInfoApiFactory(): DlqApi {
  const apiBasePath = `https://${getAppSetting("apiUrl")}/jwt`;
  return new DlqApi(new Configuration({basePath: apiBasePath}));
}

