import { Link, SpaceBetween } from "@amzn/awsui-components-react";
import {
  AccountingEventsGroup,
  DigitalBusinessDocument,
  DigitalCostDocument,
} from "../types";
import { useExternalLinks } from "../hooks/useExternalLinks";

interface Props {
  accountingEventsGroup?: AccountingEventsGroup;
  digitalBusinessDocument?: DigitalBusinessDocument;
  digitalCostDocument?: DigitalCostDocument;
}

export default function ExternalLinks(props: Props) {
  const { links } = useExternalLinks(props);

  return (
    <div>
      <SpaceBetween direction="horizontal" size="m">
        {links.map((link) => (
          <Link
            key={link.key}
            external
            externalIconAriaLabel="Opens in a new tab"
            href={link.href}
            data-testid={link.testId}
          >
            {link.text}
          </Link>
        ))}
      </SpaceBetween>
    </div>
  );
}
