import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useEffect } from "react";
import { DetailsApiFactory } from "../lusca-search-api/LuscaSearchApiFactory";
import { useHealthStateUpdate } from "../contexes/HealthStateContext";
import { AccountingEventsGroup } from "../types/events";
import { DigitalBusinessDocument } from "../types/documents";
import { CanonicalBusinessEvent } from "../types/cbes";
import useFetchDlqInfo from "./useFetchDlqInfo";
import { calculateCompleteHealthState } from "../helpers/healthUtils";
import { DetailsResponse } from "../lusca-search-api/generated-src";
import removeEmpty from "../helpers/removeEmpty";

export interface DetailsDataInterface {
  events: (AccountingEventsGroup | DigitalBusinessDocument)[];
  cbes: CanonicalBusinessEvent[];
}

const processRawData = (data: DetailsResponse): DetailsDataInterface => {
  const events = (data.events || []).map((event) => {
    const cleanEvent = removeEmpty(event);
    return "documentType" in cleanEvent
      ? (cleanEvent as DigitalBusinessDocument)
      : (cleanEvent as AccountingEventsGroup);
  });

  const cbes = (data.cbes || []).map(
    (cbe) => removeEmpty(cbe) as CanonicalBusinessEvent
  );

  return { events, cbes };
};

export default function useFetchDetails(
  stage: string,
  orderId: string
): UseQueryResult<DetailsDataInterface, Error> {
  const DetailsApi = DetailsApiFactory();
  const { setState: setHealthState } = useHealthStateUpdate();
  const dlqInfo = useFetchDlqInfo(stage, orderId);

  const query = useQuery<DetailsDataInterface, Error>(
    ["details", stage, orderId],
    async () => {
      const { data } = await DetailsApi.getDetails(stage, orderId);
      const processed = processRawData(data);

      const newHealthState = calculateCompleteHealthState(
        processed.events,
        processed.cbes,
        dlqInfo
      );
      setHealthState(newHealthState);

      return processed;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
    }
  );

  // Update health state when DLQ info changes
  useEffect(() => {
    if (query.data) {
      const newHealthState = calculateCompleteHealthState(
        query.data.events,
        query.data.cbes,
        dlqInfo
      );
      setHealthState(newHealthState);
    }
  }, [dlqInfo.status]);

  return query;
}
