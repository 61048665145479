import { useEffect, useState } from "react";
import { applyMode, Mode } from "@amzn/awsui-global-styles";
import { DARK_THEME, LIGHT_THEME } from "../constants";

export function useThemeMode(
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>
) {
  const [checked, setChecked] = useState(false);
  const [theme, setTheme] = useState(LIGHT_THEME);

  const toggleTheme = (localTheme: string | null) => {
    if (localTheme === DARK_THEME) {
      window.localStorage.setItem("theme", DARK_THEME);
      setTheme(DARK_THEME);
      applyMode(Mode.Dark);
      setDarkMode(true);
      setChecked(true);
    } else {
      window.localStorage.setItem("theme", LIGHT_THEME);
      setTheme(LIGHT_THEME);
      applyMode(Mode.Light);
      setDarkMode(false);
      setChecked(false);
    }
  };

  const handleThemeChange = (isChecked: boolean) => {
    setChecked(isChecked);
    if (theme === LIGHT_THEME) {
      toggleTheme(DARK_THEME);
    } else {
      toggleTheme(LIGHT_THEME);
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    toggleTheme(localTheme);
  }, []);

  return {
    checked,
    handleThemeChange,
  };
}
