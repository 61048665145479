import { useContext, useMemo } from "react";
import { BASE_16_CUSTOM_DARK_THEME } from "../constants";
import { ErrorDetails, JsonViewProps } from "../types";
import DarkModeContext from "../contexes/DarkModeContext";
import { getBadgeColour } from "../helpers/errorUtils";

const ERROR_TYPES_TO_EXCLUDE = ["schema_definition_processing_error"];

export function useCbeErrorContent(
  errorDetails: ErrorDetails,
  isModalVisible: boolean
) {
  const darkMode = useContext(DarkModeContext);

  const jsonViewProps: JsonViewProps = {
    displayDataTypes: false,
    displayObjectSize: false,
    quotesOnKeys: false,
    displayArrayKey: false,
    theme: darkMode ? BASE_16_CUSTOM_DARK_THEME : "rjv-default",
  } as const;

  const errorSummary = useMemo(() => {
    if (!isModalVisible) return null;

    if (
      !errorDetails?.parsedErrorDetails ||
      !Array.isArray(errorDetails.parsedErrorDetails)
    ) {
      return null;
    }

    const errorCounts: { [key: string]: number } = {};
    errorDetails.parsedErrorDetails.forEach((error) => {
      if (error && typeof error === "object" && "error_type" in error) {
        const errorType = error.error_type as string;
        if (!ERROR_TYPES_TO_EXCLUDE.includes(errorType)) {
          errorCounts[errorType] = (errorCounts[errorType] || 0) + 1;
        }
      }
    });

    return Object.entries(errorCounts).map(([errorType, count]) => ({
      errorType,
      count,
      badgeColor: getBadgeColour(errorType),
    }));
  }, [isModalVisible, errorDetails?.parsedErrorDetails]);

  return {
    jsonViewProps,
    errorSummary,
  };
}
