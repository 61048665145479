import { useCallback, useState } from "react";

export interface SectionState {
  [key: string]: boolean;
}

export function useExpandableSections(
  initialSections: Record<string, boolean>
) {
  const [expandedSections, setExpandedSections] =
    useState<SectionState>(initialSections);

  const toggleAllSections = useCallback(
    (expand: boolean) => {
      setExpandedSections(
        Object.keys(expandedSections).reduce(
          (acc, section) => ({
            ...acc,
            [section]: expand,
          }),
          {}
        )
      );
    },
    [expandedSections]
  );

  const handleSectionChange = useCallback(
    (section: string) => (event: { detail: { expanded: boolean } }) => {
      setExpandedSections((prev) => ({
        ...prev,
        [section]: event.detail.expanded,
      }));
    },
    []
  );

  return {
    expandedSections,
    toggleAllSections,
    handleSectionChange,
  };
}
