import { StatusIndicatorProps } from "@amzn/awsui-components-react/polaris/status-indicator/internal";
import {
  Box,
  BoxProps,
  Button,
  Popover,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import { useState } from "react";
import { DLQItem } from "../../lusca-search-api/generated-src";
import DlqDeepDiveModal from "./DlqDeepDiveModal";

export type DlqStatus = "loading" | "notInDlq" | "inDlq" | "error" | "unknown";

interface DlqIndicatorEntry {
  indicator: StatusIndicatorProps.Type;
  color: BoxProps.Color;
  message: string;
  minimalMessage?: string;
}

export const dlqIndicator: Record<DlqStatus, DlqIndicatorEntry> = {
  loading: {
    indicator: "loading",
    color: "text-label",
    message: "Checking DLQ...",
  },
  unknown: {
    indicator: "in-progress",
    color: "text-label",
    message: "Unknown",
  },
  notInDlq: {
    indicator: "success",
    color: "text-status-success",
    message: "Not in DLQ",
    minimalMessage: "Clear",
  },
  inDlq: {
    indicator: "warning",
    color: "text-status-warning",
    message: "Items in DLQ",
  },
  error: {
    indicator: "error",
    color: "text-status-error",
    message: "Error fetching DLQ status",
  },
};

interface DlqStatusIndicatorProps {
  status: DlqStatus;
  dlqItems?: DLQItem[];
  variant?: "default" | "minimal";
}

const DlqStatusIndicator: React.FC<DlqStatusIndicatorProps> = ({
  status,
  dlqItems = [],
  variant = "default",
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { indicator, color, message, minimalMessage } = dlqIndicator[status];

  const displayMessage =
    variant === "minimal" && minimalMessage ? minimalMessage : message;

  const handleClick = () => {
    if (status === "inDlq" && dlqItems && dlqItems.length > 0) {
      setIsModalVisible(true);
    }
  };

  const statusIndicatorContent = (
    <StatusIndicator type={indicator}>{displayMessage}</StatusIndicator>
  );

  const renderContent = () => {
    if (variant === "minimal") {
      return statusIndicatorContent;
    }

    if (status === "loading") {
      return (
        <Box color={color} display="inline-block">
          {statusIndicatorContent}
        </Box>
      );
    }

    if (status === "inDlq" && dlqItems && dlqItems.length > 0) {
      return (
        <Button
          variant="inline-link"
          onClick={handleClick}
          className="in-dlq-button"
          ariaLabel="View DLQ details"
        >
          {statusIndicatorContent}
        </Button>
      );
    }

    return (
      <Popover
        content={
          <Box padding="s">
            {status === "notInDlq"
              ? "Currently this transaction has no visible items in the DLQ. The source table for this data refreshes hourly, so may be missing recent items. "
              : "An error occurred while fetching the DLQ status. Please try again later."}
          </Box>
        }
        dismissButton={false}
        position="top"
      >
        <Button variant="inline-link" ariaLabel="View DLQ status information">
          {statusIndicatorContent}
        </Button>
      </Popover>
    );
  };

  return (
    <>
      <Box color={color} display="inline-block">
        {renderContent()}
      </Box>
      {variant === "default" &&
        status === "inDlq" &&
        dlqItems &&
        dlqItems.length > 0 && (
          <DlqDeepDiveModal
            visible={isModalVisible}
            onDismiss={() => setIsModalVisible(false)}
            dlqItems={dlqItems}
          />
        )}
    </>
  );
};

DlqStatusIndicator.defaultProps = {
  dlqItems: [],
};

export default DlqStatusIndicator;
