import {
  Link,
  SpaceBetween,
  StatusIndicator,
  TextContent,
} from "@amzn/awsui-components-react";
import {
  calculatePromoTotal,
  calculateTotalAmount,
  formatCurrency,
  formatDateTime,
  getProductFulfillmentStatus,
  hasCosts,
} from "../../../helpers/dbdUtils";
import { Section } from "./Section";
import { ExpandableTableSection } from "./ExpandableTableSection";
import { RefundsTable } from "./RefundsTable";
import { ExpandableDbdSection } from "./ExpandableDbdSection";
import { createPaystationLink } from "../../../helpers/linkUtils";
import { ProductDetails } from "../../../types/documents";

interface ProductSectionProps {
  product: ProductDetails | undefined;
  productId: string;
  expanded: boolean;
  onChange: (event: { detail: { expanded: boolean } }) => void;
  marketplaceId: string;
}

function renderProductDetails(
  details: ProductDetails["details"],
  productId: string
) {
  return (
    <Section
      title="Product Details"
      keyValuePairs={[
        { label: "Product ID", value: productId },
        { label: "Activity", value: details.activity },
        { label: "GL Product Line", value: details.glProductLineId },
        { label: "Seller of Record", value: details.sellerOfRecordId },
        { label: "Territory", value: details.offerTerritoryCountryCode },
        ...(details.listPrice
          ? [
              {
                label: "List Price",
                value: formatCurrency(
                  details.listPrice.value,
                  details.listPrice.currencyCode
                ),
              },
            ]
          : [
              {
                label: "List Price",
                value: "N/A",
              },
            ]),
        {
          label: "Fulfillment State",
          value: (
            <SpaceBetween size="xs">
              <StatusIndicator type="success">
                {details.fulfillment.state.complete
                  ? `Fulfilled at ${formatDateTime(
                      details.fulfillment.state.complete
                    )}`
                  : "Pending"}
              </StatusIndicator>
              {details.fulfillment.cancellation && (
                <StatusIndicator type="error">
                  Cancelled at{" "}
                  {formatDateTime(details.fulfillment.cancellation.at)}
                </StatusIndicator>
              )}
            </SpaceBetween>
          ),
        },
        ...(details.fulfillment.cancellation
          ? [
              {
                label: "Cancellation Reason",
                value: details.fulfillment.cancellation.reason,
              },
            ]
          : []),
        ...(details.fulfillment.period
          ? [
              {
                label: "Period Start",
                value: formatDateTime(details.fulfillment.period.start),
              },
              {
                label: "Period End",
                value: formatDateTime(details.fulfillment.period.end),
              },
            ]
          : []),
      ]}
    />
  );
}

function renderBillsSection(
  bills: ProductDetails["bills"],
  marketplaceId: string
) {
  return Object.entries(bills).map(([billId, bill]) => {
    const billTotal = calculateTotalAmount(bill.amount);
    const promoTotal = calculatePromoTotal(bill.promotions);
    const finalTotal = billTotal + promoTotal;

    const billDescription = `Original: ${formatCurrency(
      billTotal,
      bill.amount.customerTotalAmountWithoutTax.currencyCode
    )}${
      Object.keys(bill.promotions).length > 0
        ? ` | Promotions: ${formatCurrency(
            promoTotal,
            bill.amount.customerTotalAmountWithoutTax.currencyCode
          )} | Final: ${formatCurrency(
            finalTotal,
            bill.amount.customerTotalAmountWithoutTax.currencyCode
          )}`
        : ""
    }${Object.keys(bill.refunds).length > 0 ? " | Refunded" : ""}`;

    return (
      <Section
        key={billId}
        title="Bill Details"
        description={billDescription}
        keyValuePairs={[
          {
            label: "Bill ID",
            value: (
              <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href={createPaystationLink(marketplaceId, billId)}
              >
                {billId}
              </Link>
            ),
          },
          { label: "Tax ID", value: bill.taxId },
          {
            label: "Amount Without Tax",
            value: formatCurrency(
              bill.amount.customerTotalAmountWithoutTax.value,
              bill.amount.customerTotalAmountWithoutTax.currencyCode
            ),
          },
          {
            label: "Tax Amount",
            value: formatCurrency(
              bill.amount.customerTaxAmount.value,
              bill.amount.customerTaxAmount.currencyCode
            ),
          },
        ]}
      >
        {Object.keys(bill.promotions).length > 0 && (
          <ExpandableTableSection
            title="Promotions"
            items={Object.entries(bill.promotions).map(
              ([promoId, promotion]) => ({
                promoId,
                amount: formatCurrency(
                  promotion.amount.customerTotalAmountWithoutTax.value,
                  promotion.amount.customerTotalAmountWithoutTax.currencyCode
                ),
                taxAmount: formatCurrency(
                  promotion.amount.customerTaxAmount.value,
                  promotion.amount.customerTaxAmount.currencyCode
                ),
              })
            )}
            columnDefinitions={[
              { header: "Promotion ID", cell: (item) => item.promoId },
              { header: "Amount", cell: (item) => item.amount },
              { header: "Tax Amount", cell: (item) => item.taxAmount },
            ]}
          />
        )}

        {Object.keys(bill.refunds).length > 0 && (
          <RefundsTable title="Bill Refunds" refunds={bill.refunds} />
        )}
      </Section>
    );
  });
}

function renderCostsSection(costs: ProductDetails["costs"]) {
  return Object.entries(costs).map(([costId, cost]) => (
    <Section
      key={costId}
      title="Costs"
      keyValuePairs={[
        { label: "Cost Application", value: cost.costApplication },
        { label: "Transaction Type", value: cost.transactionType },
        {
          label: "Amount Without Tax",
          value: formatCurrency(
            cost.amount.customerTotalAmountWithoutTax.value,
            cost.amount.customerTotalAmountWithoutTax.currencyCode
          ),
        },
        {
          label: "Tax Amount",
          value: formatCurrency(
            cost.amount.customerTaxAmount.value,
            cost.amount.customerTaxAmount.currencyCode
          ),
        },

        {
          label: "Cost Model",
          value:
            cost.costAttributes?.contentPartnerCostAttributes?.costModel ||
            "N/A",
        },
        {
          label: "Cost Features",
          value: Object.values(cost.costFeatures).join(", ") || "None",
        },
        ...(cost.costingPeriod
          ? [
              {
                label: "Costing Period Start",
                value: formatDateTime(cost.costingPeriod.start),
              },
              {
                label: "Costing Period End",
                value: formatDateTime(cost.costingPeriod.end),
              },
            ]
          : []),
      ]}
    >
      {cost.refunds && Object.keys(cost.refunds).length > 0 && (
        <RefundsTable refunds={cost.refunds} title="Cost Refunds" />
      )}
    </Section>
  ));
}

export function ProductSection({
  product,
  productId,
  expanded,
  onChange,
  marketplaceId,
}: ProductSectionProps) {
  if (!product) {
    return (
      <ExpandableDbdSection
        title="Product Details"
        expanded={expanded}
        onChange={onChange}
        isEmpty
        emptyMessage="No product information available"
      />
    );
  }

  const { details, bills, costs } = product;

  return (
    <ExpandableDbdSection
      title="Product Details"
      expanded={expanded}
      onChange={onChange}
      description={
        <TextContent>
          <small>
            {productId}
            {details.listPrice && (
              <>
                {" "}
                | List Price:{" "}
                {formatCurrency(
                  details.listPrice.value,
                  details.listPrice.currencyCode
                )}
              </>
            )}{" "}
            | {getProductFulfillmentStatus(details)} |{" "}
            {hasCosts(product) ? "Has Costs" : "No Costs"}
          </small>
        </TextContent>
      }
    >
      <SpaceBetween size="l">
        {renderProductDetails(details, productId)}
        {renderBillsSection(bills, marketplaceId)}
        {Object.keys(costs).length > 0 && renderCostsSection(costs)}
      </SpaceBetween>
    </ExpandableDbdSection>
  );
}
