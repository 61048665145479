import { Alert, Box, Header, SpaceBetween } from "@amzn/awsui-components-react";

import { useCbeSummary } from "../../hooks/useCbeSummary";
import { GroupedRecords } from "./summary/GroupedRecords";
import { CbeInfo } from "./summary/CbeInfo";
import { CanonicalBusinessEvent } from "../../types";
import {ActionButtons} from "../common/ActionButtons";

interface CbeSummaryProps {
  cbe: CanonicalBusinessEvent;
  modalHandler: () => void;
}

function NoRecordsAlert() {
  return (
    <Box padding="l">
      <Header variant="h2">Record Summary</Header>
      <Alert type="info" header="No Records">
        There are no records to display.
      </Alert>
    </Box>
  );
}

export default function CbeSummary({ cbe, modalHandler }: CbeSummaryProps) {
  const {
    stage,
    marketplaceId,
    records,
    skipResult,
    groupedRecords,
    expandedSections,
    toggleAllSections,
    handleSectionChange,
  } = useCbeSummary(cbe);

  if (records.length === 0) {
    return <NoRecordsAlert />;
  }

  return (
    <SpaceBetween size="l">
      <ActionButtons
        modalHandler={modalHandler}
        toggleAllSections={toggleAllSections}
      />
      <CbeInfo
        cbe={cbe}
        groupedRecords={groupedRecords}
        skipResult={skipResult}
      />
      <GroupedRecords
        groupedRecords={groupedRecords}
        expandedSections={expandedSections}
        handleSectionChange={handleSectionChange}
        stage={stage}
        marketplaceId={marketplaceId}
        cbe={cbe}
      />
    </SpaceBetween>
  );
}
