import React, { lazy, Suspense } from "react";
import {
  Badge,
  ContentLayout,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import CodeView from "@amzn/awsui-code-view/code-view";
import { useCbeErrorContent } from "../../hooks/useCbeErrorContent";
import ExpandableSectionWithCopy from "../common/ExpandableSectionWithCopy";
import { ErrorDetails } from "../../types";

const ReactJson = lazy(() => import("react-json-view"));

export interface ErrorContentRendererProps {
  errorDetails: ErrorDetails;
  isModalVisible: boolean;
  expandAll: boolean;
}

const ErrorContentRenderer: React.FC<ErrorContentRendererProps> = ({
  errorDetails,
  isModalVisible,
  expandAll,
}) => {
  const { jsonViewProps, errorSummary } = useCbeErrorContent(
    errorDetails,
    isModalVisible
  );

  if (!isModalVisible) {
    return null;
  }

  return (
    <Suspense fallback={<div>Loading content...</div>}>
      <ContentLayout>
        <SpaceBetween direction="vertical" size="l">
          {errorSummary && (
            <SpaceBetween direction="horizontal" size="xs">
              {errorSummary.map(({ errorType, count, badgeColor }) => (
                <SpaceBetween direction="horizontal" size="xxs" key={errorType}>
                  <span>{count}x</span>
                  <Badge color={badgeColor}>{errorType}</Badge>
                </SpaceBetween>
              ))}
            </SpaceBetween>
          )}
          <ExpandableSectionWithCopy
            title="Error Summaries"
            copyText={errorDetails.displayMessage}
            expanded={expandAll}
          >
            <CodeView content={errorDetails.displayMessage} />
          </ExpandableSectionWithCopy>
          {errorDetails.parsedErrorDetails && (
            <ExpandableSectionWithCopy
              title="Parsed Error Details"
              copyText={JSON.stringify(
                errorDetails.parsedErrorDetails,
                null,
                2
              )}
              expanded={expandAll}
            >
              <ReactJson
                src={errorDetails.parsedErrorDetails}
                {...jsonViewProps}
              />
            </ExpandableSectionWithCopy>
          )}
          {errorDetails.errorRecords && (
            <ExpandableSectionWithCopy
              title="Error Records"
              copyText={JSON.stringify(errorDetails.errorRecords, null, 2)}
              expanded={expandAll}
            >
              <ReactJson src={errorDetails.errorRecords} {...jsonViewProps} />
            </ExpandableSectionWithCopy>
          )}
        </SpaceBetween>
      </ContentLayout>
    </Suspense>
  );
};

export default ErrorContentRenderer;
