import { Box } from "@amzn/awsui-components-react";

interface SummaryItemProps {
  label: string;
  value: React.ReactNode;
}

export function SummaryItem({ label, value }: SummaryItemProps) {
  return (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{value}</div>
    </div>
  );
}
