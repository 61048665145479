import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useRef } from "react";
import { DLQInfoResult, DLQItem } from "../lusca-search-api/generated-src";
import { DlqInfoApiFactory } from "../lusca-search-api/LuscaSearchApiFactory";
import { DlqStatus } from "../components/dlq/DlqStatusIndicator";

export interface DlqInfo {
  status: DlqStatus;
  dlqItems?: DLQItem[];
  error: Error | null;
}

export default function useFetchDlqInfo(
  stage: string,
  orderId: string
): DlqInfo {
  const DlqApi = DlqInfoApiFactory();
  const queryClient = useQueryClient();
  const hasTriggeredImmediateFetch = useRef(false);

  const { data, error } = useQuery<DLQInfoResult, Error>(
    ["dlqInfo", stage, orderId],
    async () => {
      const currentData = queryClient.getQueryData<DLQInfoResult>([
        "dlqInfo",
        stage,
        orderId,
      ]);
      const queryExecutionId = currentData?.queryExecutionId;

      const { data }: AxiosResponse<DLQInfoResult> = await DlqApi.getDLQInfo(
        stage,
        orderId,
        queryExecutionId
      );

      return data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!stage && !!orderId,
      refetchInterval: (data) => {
        if (data?.status === "PENDING") {
          if (data.queryExecutionId && !hasTriggeredImmediateFetch.current) {
            // Trigger immediate fetch only for the first time
            hasTriggeredImmediateFetch.current = true;

            setTimeout(() => {
              queryClient
                .invalidateQueries(["dlqInfo", stage, orderId])
                .catch((error) => {
                  console.error("Error invalidating queries:", error);
                });
            }, 0);
          }
          return 1000; // Continue polling every second while pending
        }
        return false; // Stop refetching once we're not in PENDING state
      },

      onSuccess: (data) => {
        if (data.status === "PENDING" && data.queryExecutionId) {
          queryClient.setQueryData<DLQInfoResult>(
            ["dlqInfo", stage, orderId],
            (oldData) => ({
              ...oldData,
              ...data,
            })
          );
        }
      },
    }
  );

  const status: DlqStatus = (() => {
    if (error) return "error";
    if (!data) return "loading";

    switch (data.status) {
      case "PENDING":
        return "loading";
      case "COMPLETED":
        return data.inDLQ ? "inDlq" : "notInDlq";
      case "ERROR":
        return "error";
      default:
        return "error";
    }
  })();

  return {
    status,
    dlqItems: data?.dlqItems,
    error: error || null,
  };
}
