import { useContext, useEffect } from "react";
import {
  createDigiConLink,
  createFlashUILink,
  createPaystationLink,
  createRaceLink,
} from "../helpers/linkUtils";
import {
  AccountingEventsGroup,
  DigitalBusinessDocument,
  DigitalCostDocument,
} from "../types";
import { OrderIdContext, StageContext } from "../contexes/OrderIdContext";
import { useMarketplaceId } from "../contexes/MarketplaceIdContext";
import { useDeferralId } from "../contexes/DeferralIdContext";
import { BETA_STAGE } from "../constants";

interface UseExternalLinksProps {
  accountingEventsGroup?: AccountingEventsGroup;
  digitalBusinessDocument?: DigitalBusinessDocument;
  digitalCostDocument?: DigitalCostDocument;
}

export function useExternalLinks({
  accountingEventsGroup,
  digitalBusinessDocument,
  digitalCostDocument,
}: UseExternalLinksProps) {
  const orderId = useContext(OrderIdContext);
  const stage = useContext(StageContext);
  const { setMarketplaceId } = useMarketplaceId();
  const { deferralIds } = useDeferralId();

  const getMarketplaceId = () => {
    if (accountingEventsGroup?.accountingEvents) {
      const firstEvent = Object.values(
        accountingEventsGroup.accountingEvents
      )[0];
      return firstEvent?.marketplaceId || "";
    }
    if (digitalBusinessDocument) {
      return digitalBusinessDocument.body.header.marketplaceId;
    }
    if (digitalCostDocument) {
      return digitalCostDocument.body.header.marketplaceId;
    }
    return "";
  };

  const getDigitalOrderId = () => {
    if (accountingEventsGroup?.accountingEvents) {
      const firstEvent = Object.values(
        accountingEventsGroup.accountingEvents
      )[0];
      return firstEvent?.orderId || "";
    }
    if (digitalBusinessDocument) {
      return digitalBusinessDocument.body.header.id;
    }
    if (digitalCostDocument) {
      return digitalCostDocument.body.header.id;
    }
    return "";
  };

  const getPaymentContractId = () => {
    if (digitalBusinessDocument?.body?.body.payments) {
      return Object.keys(digitalBusinessDocument.body.body.payments)[0] || "";
    }
    if (accountingEventsGroup?.accountingEvents) {
      const idFromFinanceTag = Object.values(
        accountingEventsGroup.accountingEvents
      )
        .map((event) => event.financeTagAuthReferenceId)
        .find((id) => id);

      if (idFromFinanceTag) {
        return idFromFinanceTag.replace(/-\d+$/, "");
      }

      const contractId = Object.values(accountingEventsGroup.accountingEvents)
        .map((event) => {
          if (event.paymentContractRevisionIds) {
            return Object.values(event.paymentContractRevisionIds)[0];
          }
          return null;
        })
        .find((id) => id);

      return contractId ? contractId.replace(/_\d+$/, "") : "";
    }
    return "";
  };

  const marketplaceId = getMarketplaceId();
  const digitalOrderId = getDigitalOrderId();
  const paymentContractId = getPaymentContractId();

  useEffect(() => {
    if (marketplaceId) {
      setMarketplaceId(marketplaceId);
    }
  }, [marketplaceId, setMarketplaceId]);

  const getLinks = () => {
    const links = [
      {
        key: "flash-ui",
        href: createFlashUILink(marketplaceId, orderId, stage),
        testId: "FlashLink",
        text: "Flash UI",
      },
      {
        key: "race-revenue",
        href: createRaceLink(
          marketplaceId,
          deferralIds.revenueDeferralId || orderId,
          stage
        ),
        testId: "RaceRevenueLink",
        text: "Race Revenue",
      },
    ];

    if (
      deferralIds.royaltyDeferralId &&
      deferralIds.royaltyDeferralId !== deferralIds.revenueDeferralId
    ) {
      links.push({
        key: "race-royalty",
        href: createRaceLink(
          marketplaceId,
          deferralIds.royaltyDeferralId,
          stage
        ),
        testId: "RaceRoyaltyLink",
        text: "Race Royalty",
      });
    }

    if (stage !== BETA_STAGE && digitalOrderId) {
      links.push({
        key: "digicon",
        href: createDigiConLink(marketplaceId, digitalOrderId),
        testId: "DigiconLink",
        text: "DigiCon",
      });
    }

    if (paymentContractId && marketplaceId) {
      links.push({
        key: "paystation",
        href: createPaystationLink(marketplaceId, paymentContractId),
        testId: "PaystationLink",
        text: "PayStation",
      });
    }

    return links;
  };

  return {
    links: getLinks(),
  };
}
