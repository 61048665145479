import { Tabs } from "@amzn/awsui-components-react";
import { createVersionNumberArray } from "../../helpers/healthUtils";
import CbeResults from "./CbeResults";
import CbeHealthStatusDisplay from "./CbeHealthStatusDisplay";
import { CanonicalBusinessEvent } from "../../types";

interface Props {
  cbeData: CanonicalBusinessEvent[];
  onVersionChange: (index: number) => void;
}

export default function CbeVersionSelector(props: Props) {
  const { cbeData, onVersionChange } = props;

  return (
    <Tabs
      tabs={createVersionNumberArray(cbeData).map((version) => ({
        id: `version-${version}`,
        label: (
          <CbeHealthStatusDisplay
            name="Version"
            events={cbeData.filter((cbe) => cbe.lockVersion === version)}
            value={version.toString()}
          />
        ),
        content: (
          <CbeResults
            cbeData={cbeData.filter((cbe) => cbe.lockVersion === version)}
          />
        ),
      }))}
      onChange={({ detail }) => {
        const selectedVersion = parseInt(detail.activeTabId.split("-")[1]);
        onVersionChange(
          cbeData.findIndex((cbe) => cbe.lockVersion === selectedVersion)
        );
      }}
    />
  );
}
