import {
  Box,
  Button,
  Cards,
  Link,
  Popover,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { useContext } from "react";
import ReactJson from "react-json-view";
import { formatCurrencyValue } from "../../helpers/cbeSummaryUtils";
import DarkModeContext from "../../contexes/DarkModeContext";
import { BASE_16_CUSTOM_DARK_THEME } from "../../constants";
import createFlareConfigLink from "../../helpers/flare-config-utils";
import {
  createFlashUIMatcherLink,
  createRaceLink,
} from "../../helpers/linkUtils";
import { CanonicalBusinessEvent, CBERecord } from "../../types";

interface RecordCardProps {
  item: CBERecord;
  cbe: CanonicalBusinessEvent;
  stage: string;
  marketplaceId: string;
}

interface FieldProps {
  label: string;
  value: React.ReactNode;
  link?: string;
}

function renderAmount(item: CBERecord): React.ReactElement {
  const amount = parseFloat(
    item.content?.functionalAmount?.value ?? item.functionalAmount?.value ?? "0"
  );
  const entryType = item.content?.entryType ?? item.entryType ?? "UNKNOWN";
  const currencyCode =
    item.content?.functionalAmount?.currencyCode ??
    item.functionalAmount?.currencyCode ??
    "";

  const isDebit =
    (entryType === "DEBIT" && amount > 0) ||
    (entryType === "CREDIT" && amount < 0);

  return (
    <Box color={isDebit ? "text-status-error" : "text-status-success"}>
      {formatCurrencyValue(Math.abs(amount), currencyCode)} ({entryType})
    </Box>
  );
}

function Field({ label, value, link }: FieldProps) {
  if (value === undefined) return null;

  return (
    <div>
      <strong>{label}</strong>
      <br />
      {link ? (
        <Link href={link} external>
          {value}
        </Link>
      ) : (
        value
      )}
    </div>
  );
}

function MainInfoSection({ item }: { item: CBERecord }) {
  return (
    <div style={{ height: "150px" }}>
      <SpaceBetween direction="vertical" size="s">
        <Field label="Amount" value={renderAmount(item)} />
        <Field
          label="Financial Component"
          value={
            item.content?.financialComponent ??
            item.flareConfigFinancialComponent
          }
        />
        <Field
          label="Financial Component Group"
          value={
            item.content?.financialComponentGroup ??
            item.flareConfigFinancialComponentGroup
          }
        />
      </SpaceBetween>
    </div>
  );
}

function AdditionalInfoSection({
  item,
  marketplaceId,
  stage,
}: {
  item: CBERecord;
  marketplaceId: string;
  stage: string;
}) {
  const deferralId =
    item.clientAttributes?.deferral_id?.stringValue ??
    item.content?.clientAttributes?.deferral_id?.stringValue;

  const rcslEntrySourceId =
    item.clientAttributes?.rcsl_entry_source_id?.stringValue ??
    item.content?.clientAttributes?.rcsl_entry_source_id?.stringValue;

  return (
    <div style={{ height: "120px" }}>
      <SpaceBetween direction="vertical" size="s">
        <Field
          label="Account"
          value={item.content?.accountType ?? item.accountType}
        />
        {rcslEntrySourceId && (
          <Field
            label="RCSL Entry Source Id"
            value={`${rcslEntrySourceId}`}
            link={createFlashUIMatcherLink(
              marketplaceId,
              rcslEntrySourceId,
              stage
            )}
          />
        )}
        {deferralId && (
          <Field
            label="Deferral Id"
            value={deferralId}
            link={createRaceLink(marketplaceId, deferralId, stage)}
          />
        )}
        <Field
          label="Tax Jurisdiction Name"
          value={
            item.clientAttributes?.tax_jurisdiction_name?.stringValue ??
            item.content?.clientAttributes?.tax_jurisdiction_name?.stringValue
          }
        />
      </SpaceBetween>
    </div>
  );
}

function ActionsSection({
  item,
  cbe,
  stage,
}: {
  item: CBERecord;
  cbe: CanonicalBusinessEvent;
  stage: string;
}) {
  const darkMode = useContext(DarkModeContext);

  return (
    <SpaceBetween direction="horizontal" size="xxxs">
      <Popover
        position="top"
        size="large"
        triggerType="custom"
        content={
          <Box padding="s">
            <ReactJson
              src={item}
              collapsed={2}
              displayDataTypes={false}
              sortKeys
              theme={darkMode ? BASE_16_CUSTOM_DARK_THEME : "rjv-default"}
            />
          </Box>
        }
      >
        <Button iconName="command-prompt" variant="link">
          Show JSON
        </Button>
      </Popover>
      <Button
        variant="link"
        href={createFlareConfigLink(cbe, item, stage)}
        target="_blank"
        rel="noopener noreferrer"
        iconAlign="right"
        iconName="external"
      >
        Flare Config
      </Button>
    </SpaceBetween>
  );
}

export function RecordCard({
  item,
  cbe,
  stage,
  marketplaceId,
}: RecordCardProps) {
  const sections = [
    {
      id: "mainInfo",
      content: (record: CBERecord) => <MainInfoSection item={record} />,
    },
    {
      id: "additionalInfo",
      content: (record: CBERecord) => (
        <AdditionalInfoSection
          item={record}
          marketplaceId={marketplaceId}
          stage={stage}
        />
      ),
    },
    {
      id: "actions",
      content: (record: CBERecord) => (
        <Box margin={{ top: "l" }}>
          <ActionsSection item={record} cbe={cbe} stage={stage} />
        </Box>
      ),
    },
  ];

  return (
    <Cards
      items={[item]}
      cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
      cardDefinition={{
        header: () =>
          item["@type"] ?? item.flareConfigFinancialComponent ?? "Unknown",
        sections,
      }}
    />
  );
}
