import { MARKETPLACE_TO_REALM, REALMS } from "./marketplaceUtils";
import { BETA_STAGE, PROD_STAGE } from "../constants";

export const createPaystationLink = (
  marketplaceId: string,
  paymentContractId: string
) => {
  const realm = MARKETPLACE_TO_REALM[marketplaceId].toLowerCase();
  return `https://paystation-console-${realm}.aka.amazon.com/pces?api=byPaymentContractId&paymentContractId=${encodeURIComponent(
    paymentContractId
  )}`;
};

export const createFlashUILink = (
  marketplaceId: string,
  orderId: string,
  selectedStage: string
) => {
  const realm = MARKETPLACE_TO_REALM[marketplaceId];
  const stage =
    selectedStage === PROD_STAGE ? "" : `-${selectedStage.toLowerCase()}`;
  const alias = selectedStage === BETA_STAGE ? "integ" : "aka";
  return `https://flashui${stage}-${realm}.${alias}.amazon.com/search/transaction?transactionId=${encodeURIComponent(
    orderId
  )}`;
};

export const createFlashUIMatcherLink = (
  marketplaceId: string,
  rcslEntrySourceId: string,
  selectedStage: string
) => {
  const realm = MARKETPLACE_TO_REALM[marketplaceId];
  const stage =
    selectedStage === PROD_STAGE ? "" : `-${selectedStage.toLowerCase()}`;
  const alias = selectedStage === BETA_STAGE ? "integ" : "aka";
  const domain = selectedStage === PROD_STAGE ? "" : "&domain=uat";

  return `https://flashui${stage}-${realm}.${alias}.amazon.com/search/matcher?matchKey=${encodeURIComponent(
    rcslEntrySourceId
  )}&accountNumber=23110&realm=${realm}Amazon${domain}`;
};

export const createDigiConLink = (marketplaceId: string, orderId: string) => {
  const realm = MARKETPLACE_TO_REALM[marketplaceId];
  if (realm !== REALMS.NA) {
    const realmSuffix = `${realm.toUpperCase()}Amazon`;
    return `https://digicon-${realm}.amazon.com/order-dumper?orderID=${orderId}&realm=${realmSuffix}`;
  }
  return `https://digicon.amazon.com/order-dumper?orderID=${orderId}`;
};

export const createRaceLink = (
  marketplaceId: string,
  deferralId: string,
  selectedStage: string
) => {
  const namespace = selectedStage === PROD_STAGE ? "prod" : "uat";
  if (selectedStage === BETA_STAGE) {
    return `https://race-website.integ.amazon.com:13743/deferral_search/index?deferral-id=${encodeURIComponent(
      deferralId
    )}&namespace=test`;
  }
  const realm = MARKETPLACE_TO_REALM[marketplaceId];
  return `https://race-website-${realm}.aka.amazon.com/deferral_search/index?deferral-id=${encodeURIComponent(
    deferralId
  )}&namespace=${namespace}`;
};
