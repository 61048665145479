import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { InputProps } from "@amzn/awsui-components-react/polaris/input";
import { useMarketplaceId } from "../contexes/MarketplaceIdContext";
import {
  useHealthState,
  useHealthStateUpdate,
} from "../contexes/HealthStateContext";
import { getCountryCodeFromMarketplace } from "../helpers/marketplaceUtils";
import useFetchDetails from "./useFetchDetails";
import { SearchHistoryItem } from "../types";

export function useSearch(stage: string, setStage: (stage: string) => void) {
  const [searchParams, setSearchParams] = useSearchParams();
  const orderIdParam =
    searchParams.get("transactionId") || searchParams.get("orderId");
  const stageParam = searchParams.get("stage") || "Prod";

  const [orderId, setOrderId] = useState("");
  const [orderIdQuery, setOrderIdQuery] = useState(orderIdParam || "");
  const [isStageDropdownDisabled, setStageDropdownDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: stageParam,
    value: stageParam,
  });

  const { refetch: refetchDetails } = useFetchDetails(stage, orderId);

  const { marketplaceId, resetMarketplaceId } = useMarketplaceId();
  const healthState = useHealthState();
  const { resetHealthState } = useHealthStateUpdate();

  const updateSearchHistory = (
    newEntry: Partial<SearchHistoryItem>,
    maxEntries = 300
  ) => {
    if (!orderId || !orderId.trim()) return;

    const existingEntries: SearchHistoryItem[] = JSON.parse(
      localStorage.getItem("orderIds") || "[]"
    );

    const existingEntry = existingEntries.find(
      (entry) => entry.orderId === orderId && entry.stage === stage
    );

    if (existingEntry) {
      Object.assign(existingEntry, newEntry);
      existingEntry.date = new Date().toLocaleString();
    } else {
      existingEntries.unshift({
        ...newEntry,
        orderId,
        stage,
        date: new Date().toLocaleString(),
      } as SearchHistoryItem);
    }

    localStorage.setItem(
      "orderIds",
      JSON.stringify(existingEntries.slice(0, maxEntries))
    );
  };

  const handleSearch = () => {
    // Only reset states if we're searching for a different order or stage
    if (orderIdQuery !== orderId || selectedOption.value !== stage) {
      resetMarketplaceId();
      resetHealthState();
    }

    setOrderId(orderIdQuery);
    setStage(selectedOption.value);

    if (orderIdQuery !== "") {
      setSearchParams(
        { stage: selectedOption.value, transactionId: orderIdQuery },
        { replace: true }
      );
    }
  };

  const handleStageSelect = (selectedStage: OptionDefinition) => {
    setSelectedOption({
      label: selectedStage.label!,
      value: selectedStage.value!,
    });
  };

  const handleKeyDown = (detail: InputProps.KeyDetail) => {
    if (detail.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    if (orderIdParam) setOrderId(orderIdParam);
  }, [orderIdParam]);

  useEffect(() => {
    if (stageParam) setStage(stageParam);
  }, [stageParam]);

  useEffect(() => {
    if (orderId && stage) {
      (async () => {
        setStageDropdownDisabled(true);
        try {
          await refetchDetails();
          updateSearchHistory({ orderId, stage });
        } catch (error) {
          console.error(error);
        } finally {
          setStageDropdownDisabled(false);
        }
      })();
    }
  }, [orderId, stage]);

  useEffect(() => {
    if (orderId && stage && marketplaceId) {
      const countryCode = getCountryCodeFromMarketplace(marketplaceId);
      if (countryCode.length > 0) {
        updateSearchHistory({ countryCode });
      }
    }
  }, [marketplaceId, orderId, stage]);

  useEffect(() => {
    if (
      orderId &&
      stage &&
      healthState.overallHealth &&
      healthState.overallHealth.toLowerCase() !== "unknown"
    ) {
      updateSearchHistory({ status: healthState.overallHealth });
    }
  }, [healthState.overallHealth, orderId, stage]);

  useEffect(() => {
    if (orderId) {
      document.title = `${orderId} | Lusca Search`;
    }
  }, [orderId]);

  useEffect(() => {
    return () => {
      setOrderId("");
      setOrderIdQuery("");
    };
  }, []);

  return {
    orderId,
    orderIdQuery,
    isStageDropdownDisabled,
    selectedOption,
    setOrderIdQuery,
    handleSearch,
    handleStageSelect,
    handleKeyDown,
  };
}
