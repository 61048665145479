import {
  Box,
  Container,
  KeyValuePairs,
  Popover,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import {
  BalanceCheckResult,
  CanonicalBusinessEvent,
  GroupedRecordsInterface,
} from "../../../types/cbes";
import { getBalanceStatus } from "../../../helpers/cbeBalanceUtils";
import { getStatusIndicatorType } from "../../../helpers/cbeSummaryUtils";
import { BalanceDetails } from "./BalanceDetails";

export function CbeInfo({
  cbe,
  groupedRecords,
  skipResult,
}: {
  cbe: CanonicalBusinessEvent;
  groupedRecords: GroupedRecordsInterface;
  skipResult: BalanceCheckResult;
}) {
  const { status, messages } = getBalanceStatus(groupedRecords, skipResult);

  return (
    <Container>
      <KeyValuePairs
        columns={2}
        minColumnWidth={0}
        items={[
          { label: "Business Activity", value: cbe.businessActivity },
          { label: "Business Namespace", value: cbe.businessNamespace },
          { label: "Business Country Code", value: cbe.businessCountryCode },
          {
            label: "Balance Check",
            value: (
              <Popover
                size="large"
                position="bottom"
                dismissButton={false}
                triggerType="text"
                content={
                  <Box padding="s">
                    <SpaceBetween size="s">
                      {messages.length > 0 ? (
                        messages
                      ) : (
                        <BalanceDetails groupedRecords={groupedRecords} />
                      )}
                    </SpaceBetween>
                  </Box>
                }
              >
                <StatusIndicator type={getStatusIndicatorType(status)}>
                  {status}
                </StatusIndicator>
              </Popover>
            ),
          },
        ]}
      />
    </Container>
  );
}
