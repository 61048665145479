import {
  Container,
  Header,
  KeyValuePairs,
  Link,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import {
  formatCurrency,
  formatDateTime,
  getRefundStatus,
  getStatusType,
  hasRefunds,
} from "../../../helpers/dbdUtils";
import { ExpandableDbdSection } from "./ExpandableDbdSection";
import { RefundsTable } from "./RefundsTable";
import { createPaystationLink } from "../../../helpers/linkUtils";
import { PaymentContract, PaymentMethod } from "../../../types/documents";

interface PaymentSectionProps {
  payment: PaymentContract | undefined;
  paymentId: string;
  expanded: boolean;
  onChange: (event: { detail: { expanded: boolean } }) => void;
}

function renderPaymentMethod(
  methodId: string,
  method: PaymentMethod,
  marketplaceId: string
) {
  const paymentMethod = method.paymentProvider.amazonManagedPayment.name;

  return (
    <Container key={methodId}>
      <Header variant="h3">Payment Method</Header>
      <SpaceBetween size="m">
        <KeyValuePairs
          columns={2}
          items={[
            {
              label: "Payment ID",
              value: (
                <Link
                  external
                  externalIconAriaLabel="Opens in a new tab"
                  href={createPaystationLink(marketplaceId, methodId)}
                >
                  {methodId}
                </Link>
              ),
            },
            { label: "Payment Provider", value: paymentMethod },
            {
              label: "Amount Without Tax",
              value: formatCurrency(
                method.amount.customerTotalAmountWithoutTax.value,
                method.amount.customerTotalAmountWithoutTax.currencyCode
              ),
            },
            {
              label: "Tax Amount",
              value: formatCurrency(
                method.amount.customerTaxAmount.value,
                method.amount.customerTaxAmount.currencyCode
              ),
            },

            {
              label: "Payment Status",
              value: (
                <StatusIndicator
                  type={getStatusType(
                    method.obligation.state.complete,
                    method.obligation.state.failed
                  )}
                >
                  {method.obligation.state.complete
                    ? `Paid (${paymentMethod})`
                    : method.obligation.state.failed
                    ? "Failed"
                    : "Pending"}
                </StatusIndicator>
              ),
            },
            ...(method.obligation.state.complete
              ? [
                  {
                    label: "Payment Completed Date",
                    value: formatDateTime(method.obligation.state.complete.at),
                  },
                ]
              : []),
            ...(method.obligation.state.failed
              ? [
                  {
                    label: "Payment Failed Date",
                    value: formatDateTime(method.obligation.state.failed.at),
                  },
                ]
              : []),
          ]}
        />

        {hasRefunds(method) && (
          <RefundsTable
            title="Payment Refunds"
            refunds={method.obligation.refunds}
          />
        )}
      </SpaceBetween>
    </Container>
  );
}

export function PaymentSection({
  payment,
  paymentId,
  expanded,
  onChange,
}: PaymentSectionProps) {
  if (!payment || !payment?.paymentMethods?.[paymentId]) {
    return (
      <ExpandableDbdSection
        title="Payment Details"
        expanded={expanded}
        onChange={onChange}
        isEmpty
        emptyMessage="No payment information available"
      />
    );
  }

  const method = payment.paymentMethods[paymentId];
  const { marketplaceId } = method;

  return (
    <ExpandableDbdSection
      title="Payment Details"
      expanded={expanded}
      onChange={onChange}
      description={
        <small>
          {[
            method.paymentProvider.amazonManagedPayment.name,
            `Amount: ${formatCurrency(
              method.amount.customerTotalAmountWithoutTax.value,
              method.amount.customerTotalAmountWithoutTax.currencyCode
            )} + Tax: ${formatCurrency(
              method.amount.customerTaxAmount.value,
              method.amount.customerTaxAmount.currencyCode
            )}`,
            method.obligation.state.complete
              ? "Paid"
              : method.obligation.state.failed
              ? "Failed"
              : "Pending",
            getRefundStatus(method).text,
          ].join(" | ")}
        </small>
      }
    >
      <SpaceBetween size="l">
        {Object.entries(payment.paymentMethods).map(([methodId, method]) =>
          renderPaymentMethod(methodId, method, marketplaceId)
        )}
      </SpaceBetween>
    </ExpandableDbdSection>
  );
}
