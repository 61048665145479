import {
  Container,
  Header,
  KeyValuePairs,
  SpaceBetween,
  TextContent,
} from "@amzn/awsui-components-react";

interface SectionProps {
  title: string;
  description?: string | React.ReactNode;
  keyValuePairs?: Array<{ label: string; value: any }>;
  children?: React.ReactNode;
}

export function Section({
  title,
  description,
  keyValuePairs,
  children,
}: SectionProps) {
  return (
    <Container>
      <Header variant="h3">
        {title}
        {description && (
          <>
            <br />
            <TextContent>
              <small>{description}</small>
            </TextContent>
          </>
        )}
      </Header>
      <SpaceBetween size="m">
        {keyValuePairs && <KeyValuePairs columns={2} items={keyValuePairs} />}
        {children}
      </SpaceBetween>
    </Container>
  );
}
