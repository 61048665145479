import React, { createContext, ReactNode, useContext, useState } from "react";

interface MarketplaceIdContextProps {
  marketplaceId: string;
  setMarketplaceId: (id: string) => void;
  resetMarketplaceId: () => void;
}

const MarketplaceIdContext = createContext<
  MarketplaceIdContextProps | undefined
>(undefined);

export function MarketplaceIdProvider({ children }: { children: ReactNode }) {
  const [marketplaceId, setMarketplaceId] = useState<string>("");

  const resetMarketplaceId = () => {
    setMarketplaceId("");
  };

  return (
    <MarketplaceIdContext.Provider
      value={{ marketplaceId, setMarketplaceId, resetMarketplaceId }}
    >
      {children}
    </MarketplaceIdContext.Provider>
  );
}

export const useMarketplaceId = () => {
  const context = useContext(MarketplaceIdContext);
  if (!context) {
    throw new Error(
      "useMarketplaceId must be used within MarketplaceIdProvider"
    );
  }
  return context;
};
