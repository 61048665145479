import React, { useRef } from "react";
import {
  Box,
  Button,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from "@amzn/awsui-components-react";
import { COLUMN_DEFINITIONS } from "../config/searchTableConfig";
import { useSearchHistory } from "../hooks/useSearchHistory";

export default function SearchHistory() {
  const textFilterRef = useRef(null);
  const {
    items,
    collectionProps,
    filterProps,
    paginationProps,
    deleteSelectedItems,
  } = useSearchHistory();

  return (
    <Table
      {...collectionProps}
      resizableColumns
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      loadingText="Loading resources"
      selectionType="multi"
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={deleteSelectedItems}
                disabled={collectionProps.selectedItems?.length === 0}
              >
                Delete selected
              </Button>
            </SpaceBetween>
          }
        >
          Recent Searches
        </Header>
      }
      filter={
        <TextFilter
          {...filterProps}
          ref={textFilterRef}
          filteringPlaceholder="Filter by Order ID, Country, Stage, or Status"
        />
      }
      pagination={<Pagination {...paginationProps} />}
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No resources to display.
          </Box>
        </Box>
      }
    />
  );
}
