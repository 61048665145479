import React from "react";
import { Box, Popover, StatusIndicator } from "@amzn/awsui-components-react";
import { HealthStatus } from "../../types";
import healthIndicator from "../../config/healthIndicator";

interface HealthStatusIndicatorProps {
  healthStatus: HealthStatus;
  popoverContent?: React.ReactNode;
  minimal?: boolean;
  isLoading?: boolean;
}

const HealthStatusIndicator: React.FC<HealthStatusIndicatorProps> = ({
  healthStatus,
  popoverContent,
  minimal = false,
  isLoading = false,
}) => {
  if (isLoading) {
    return (
      <Box display="inline-block">
        <StatusIndicator type="loading">Loading</StatusIndicator>{" "}
      </Box>
    );
  }

  const { type, color, message } =
    healthIndicator[healthStatus] || healthIndicator.unknown;

  const statusIndicatorContent = (
    <StatusIndicator type={type}>
      {minimal
        ? null
        : healthStatus.charAt(0).toUpperCase() + healthStatus.slice(1)}
    </StatusIndicator>
  );

  return (
    <Box color={color}>
      {minimal || !popoverContent ? (
        statusIndicatorContent
      ) : (
        <Popover header={message} content={popoverContent}>
          {statusIndicatorContent}
        </Popover>
      )}
    </Box>
  );
};

export default HealthStatusIndicator;
