import React from "react";
import {
  Button,
  CopyToClipboard,
  Popover,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import CodeView from "@amzn/awsui-code-view/code-view";
import { generateMerlonLogsUrl, getQuery } from "../../helpers/logUtils";

interface LogButtonsProps {
  identifier: string;
  stage: string;
  logGroups: string[];
  dateRange: [Date, Date];
  isDlq?: boolean;
}

const LogButtons: React.FC<LogButtonsProps> = ({
  identifier,
  stage,
  logGroups,
  dateRange,
  isDlq = false,
}) => {
  const logQuery = getQuery(identifier, isDlq);

  return (
    <SpaceBetween alignItems="center" direction="horizontal" size="xxs">
      <Popover
        dismissButton={false}
        fixedWidth={false}
        position="bottom"
        size="large"
        triggerType="custom"
        content={
          <CodeView
            content={logQuery}
            actions={
              <CopyToClipboard
                copyButtonAriaLabel="Copy code"
                copyErrorText="Code failed to copy"
                copySuccessText="Code copied"
                textToCopy={logQuery}
              />
            }
          />
        }
      >
        <Button iconName="status-info" variant="inline-icon" />
      </Popover>
      <Button
        href={generateMerlonLogsUrl(stage, logGroups, logQuery, ...dateRange)}
        target="_blank"
        rel="noopener noreferrer"
        iconName="external"
      >
        View Logs
      </Button>
    </SpaceBetween>
  );
};

export default LogButtons;
