import React, { ReactNode, Suspense, useEffect, useState } from "react";
import {
  CopyToClipboard,
  ExpandableSection,
} from "@amzn/awsui-components-react";

interface ExpandableSectionWithCopyProps {
  title: ReactNode;
  copyText: string;
  children: React.ReactNode;
  suspenseFallback?: React.ReactNode;
  expanded: boolean;
}

const ExpandableSectionWithCopy: React.FC<ExpandableSectionWithCopyProps> = ({
  title,
  copyText,
  children,
  suspenseFallback = <div>Loading...</div>,
  expanded,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <ExpandableSection
      expanded={isExpanded}
      onChange={({ detail }) => setIsExpanded(detail.expanded)}
      headerText={title}
      variant="container"
      headerActions={
        <CopyToClipboard
          copyButtonAriaLabel="Copy code"
          copyErrorText="Failed to copy"
          copySuccessText="Copied"
          textToCopy={copyText}
        />
      }
    >
      <Suspense fallback={suspenseFallback}>{children}</Suspense>
    </ExpandableSection>
  );
};

export default ExpandableSectionWithCopy;
