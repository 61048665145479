import { useMemo } from "react";
import { GroupedRecordsInterface } from "../types/cbes";
import { calculateDebitCreditTotals } from "../helpers/cbeBalanceUtils";
import { formatCurrencyValue } from "../helpers/cbeSummaryUtils";

export function useBalanceDetails(groupedRecords: GroupedRecordsInterface) {
  const items = useMemo(
    () =>
      Object.entries(groupedRecords)
        .map(([group, { debits, credits }]) => {
          const totals = calculateDebitCreditTotals(debits, credits);
          return Object.entries(totals).map(
            ([currencyCode, { debits, credits }]) => ({
              group,
              currencyCode,
              debits: formatCurrencyValue(debits, currencyCode),
              credits: formatCurrencyValue(credits, currencyCode),
            })
          );
        })
        .flat(),
    [groupedRecords]
  );

  return { items };
}
