import { useState } from "react";
import {
  Alert,
  Box,
  Container,
  ExpandableSection,
  TextContent,
} from "@amzn/awsui-components-react";
import PublishStatusComponent from "./PublishStatusComponent";
import {
  cbeWithoutPublishStatus,
  getAccountingOperationColor,
} from "../../helpers/cbeSummaryUtils";
import CbeSummary from "./CbeSummary";
import { CanonicalBusinessEvent } from "../../types";
import { JsonModal } from "../common/JsonModal";

interface Props {
  cbe: CanonicalBusinessEvent;
}

export default function CbeEntry(props: Props) {
  const { cbe } = props;
  const [isJsonModalVisible, setIsJsonModalVisible] = useState(false);

  const hasRecords = Array.isArray(cbe.records)
    ? cbe.records.length > 0
    : Object.keys(cbe.records || {}).length > 0;

  return (
    <Container>
      <PublishStatusComponent cbe={cbe} />
      <ExpandableSection
        variant="default"
        header={
          <Box variant="h4">
            {cbe.idempotenceId} (V{cbe.idempotenceVersion})
            <br />
            {cbe.creationDate && (
              <TextContent>
                <small>
                  Accounting Operation:{" "}
                  <Box
                    color={getAccountingOperationColor(cbe.accountingOperation)}
                    variant="small"
                  >
                    {cbe.accountingOperation}
                  </Box>
                </small>{" "}
                <br />
                <small>Destination: {cbe.destination} </small>
                <br />
                <small>
                  Created on:{" "}
                  {new Date(cbe.creationDate).toISOString().replace("T", " ")}
                </small>
                <br />
                <small>
                  PK: {cbe.pk}, SK: {cbe.sk}
                </small>
                <br />
                <small>Source Group Version: {cbe.sourceGroupVersion}</small>
              </TextContent>
            )}
          </Box>
        }
      >
        {hasRecords ? (
          <CbeSummary
            cbe={cbe}
            modalHandler={() => setIsJsonModalVisible(true)}
          />
        ) : (
          <Alert type="info" header="No Records">
            There are no records to display for this entry.
          </Alert>
        )}
      </ExpandableSection>

      <JsonModal
        title={cbe.idempotenceId}
        content={cbeWithoutPublishStatus(cbe)}
        visible={isJsonModalVisible}
        onDismiss={() => setIsJsonModalVisible(false)}
        initialCollapsed={2}
      />
    </Container>
  );
}
